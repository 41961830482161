<template>
  <div class="promotion-lable" :class="{ promotion: true }">
    <currency class="icon" :fill="'black'" />
    {{ cashbackLabel }}
  </div>
</template>

<script>
import Currency from "../svg/Currency.vue";

export default {
  name: "PromoLabel",
  props: ["cashback"],
  components: { Currency },
  computed: {
    cashbackLabel() {
      if (!this.cashback) return "";
      const type = this.cashback.type;

      if (type === "percentage") {
        return `${this.cashback.percentage}% cashback`;
      } else if (type === "amount" || type === "currency") {
        const value = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(this.cashback.amount);
        return `${value} cashback`;
      } else {
        return "";
      }
    },
  }
}
</script>

<style scoped>
.promotion-lable {
  display: inline-flex;
  background: rgb(251, 44, 111);
  color: rgb(255, 255, 255);
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 16px;
  font-size: var(--sm);
  letter-spacing: 0;
  line-height: 16px;
  text-transform: capitalize;
  min-height: 30px;
}

.promotion {
  background-color: rgb(251, 204, 44);
  color: rgb(0, 0, 0);
}

.icon {
  margin-right: 10px;
}
</style>
