<template>
  <button
    class="px-6 py-3 text-lg rounded-full button"
    @click="!disabled ? $emit('click') : $emit('disabledClick')"
    :class="{ [initType.toLowerCase()]: true, disabled: disabledProp, 'button--loading': loading }"
  >
    <span class="button__text">
      {{ text }}
      <slot v-html="!text" />
    </span>
  </button>
</template>

<script>
export default {
  name: "DefaultButton",
  props: ["text", "type", "disabled", "loading"],
  computed: {
    disabledProp() {
      return !this.disabled ? false : this.disabled;
    },

    initType() {
      return !this.type ? "white" : this.type;
    }
  }
};
</script>

<style scoped>
.button:active {
  outline: none;
  border: none;
}

.button {
  height: 52px;
  color: black;
  background-color: white;
  position: relative;
  cursor: pointer;
}

.button__text {
  transition: all 0.2s;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.white {
  color: black;
  background-color: white;
}

.white-border {
  color: black;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.disabled {
  opacity: 0.5;
}

.gray {
  background-color: rgb(245, 248, 249);
}

.gradient {
  color: white;
  background-image: linear-gradient(
    257deg,
    #ff9d8b 5%,
    #fa166a 51%,
    #f5166a 58%,
    #e8166a 66%,
    #d2166a 75%,
    #b3166a 84%,
    #8b166a 93%,
    #73166a 99%
  );
}
</style>
