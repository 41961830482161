<template>
  <div class="overlay">
    <div class="content">
      <div class="image">
        <div class="close" @click="close">
          <img src="@/assets/img/icons/cross.svg" alt="close" />
        </div>
      </div>
      <div class="inner-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: ["close"]
};
</script>

<style scoped>
.overlay {
  color: white;
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  display: flex;
  justify-content: center;
  align-items: center;
}

.close {
  background-color: var(--black);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  height: 440px;
  width: 535px;
  background-color: white;
}

.inner-content {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 25px 0;
  flex-direction: column;
}

.inner-content h1 {
  font-size: var(--xl3);
  line-height: var(--xl3);
  margin-bottom: 10px;
  color: black;
}

.inner-content p {
  color: var(--gray);
  font-family: "GT Walsheim Pro";
  font-size: var(--m);
  letter-spacing: 0;
  line-height: 24px;
}

.image {
  background: url("../../assets/img/message.png") no-repeat center center;
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  background-size: cover;
}

@media only screen and (max-width: 600px) {
  .content {
    height: 100%;
    width: 100%;
    background-color: white;
  }

  .image {
    height: 35%;
    background-size: cover;
  }
}
</style>
