<template>
  <div class="page">
    <div class="content-wrapper">
      <small-header-component />
      <slot />
    </div>
    <img
      class="image hide-mobile"
      src="../../assets/img/heroin.png"
      alt="raboom-login-image"
    />
  </div>
</template>

<script>
import SmallHeaderComponent from "./SmallHeaderComponent.vue";
export default {
  components: { SmallHeaderComponent },
  name: "HeroImage"
};
</script>

<style scoped>
.image {
  max-height: 900px;
}

.content-wrapper {
  padding: 20px 80px;
  width: 50%;
  max-width: 800px;
  min-height: 100%;
}

.page {
  width: 100%;
  height: 100%;
  padding-bottom: 80px 0 0;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
  .hide-mobile {
    display: none;
  }

  .content-wrapper {
    padding: 20px 15px;
    width: 100%;
  }

  .page {
    padding-bottom: 40px;
  }
}
</style>
