import { TOKEN } from "../constants/constants";

const parseJwt = (token) => JSON.parse(atob(token.split(".")[1]));

const currentDateInSeconds = () => new Date().getTime() / 1000;

const tokenIsValid = () => {
  const tokensInStorage = localStorage.getItem(TOKEN);
  return tokensInStorage
    ? parseJwt(tokensInStorage).exp > currentDateInSeconds()
    : false;
};

const validateEmail = (email) => {
  const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEx.test(String(email).toLowerCase());
};

const validateBirthdate = (birthDate) => {
  const regEx = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
  return regEx.test(String(birthDate).toLowerCase());
};

const validatePassword = (password) => {
  const regEx = /^.{8,}$/;
  return regEx.test(String(password).toLowerCase());
}

const validateIban = (iban) => {
  const regex = /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/gim;
  return regex.test(String(iban.trim()).toLowerCase());
};

const isNotEmpty = (string) => {
  if (string && string !== "") {
    const isValid = string.trim() !== "";

    return isValid;
  }

  return false;
};

const slugify = (string) => {
  string = string.replace(/^\s+|\s+$/g, ""); // trim
  string = string.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    string = string.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  string = string
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return string;
};

const goToShop = (route, router, shop) => {
  const routeName =
    route.name === "Registration" ||
      route.name === "Registration-new" ||
      route.name === "Registration-nieuw"
      ? "registreren"
      : route.name;

  router.push({
    path: `/${routeName.toLowerCase()}/${shop.store.urlPathSegment}/${shop.urlPathSegment
      }`,
  });
};

const goToStore = (route, router, store) => {
  const routeName =
    route.name === "Registration" ||
      route.name === "Registration-new" ||
      route.name === "Registration-nieuw"
      ? "registreren"
      : route.name;

  router.push({
    path: `/${routeName.toLowerCase()}/${store.urlPathSegment}`,
  });
};

const getFirstPartOfEmail = ({ email }) => email.split("@")[0]

export {
  parseJwt,
  currentDateInSeconds,
  tokenIsValid,
  validateEmail,
  validateBirthdate,
  validateIban,
  validatePassword,
  isNotEmpty,
  goToShop,
  goToStore,
  slugify,
  getFirstPartOfEmail
}
