<template>
  <header class="m-0 p-0">
    <div class="container mx-auto header">
      <div class="flex items-center justify-between py-6">
        <router-link :to="{ name: 'Home' }">
          <img class="logo" src="@/assets/img/icons/logo.svg" alt="Logo" />
        </router-link>
        <nav>
          <ul class="flex items-center">
            <li v-if="loggedIn" class="mx-2 hide-mobile">
              <p>Totaal bespaard</p>
              <p>€{{ totalCashback }}</p>
            </li>
            <li class="mx-2 text-white hide-mobile">
              <router-link :to="{ name: 'Support' }">Support</router-link>
            </li>
            <li class="mx-2" v-if="loggedIn">
              <default-button @click="logout" text="Uitloggen" />
            </li>
            <li class="mx-2" :class="{ ['hide-mobile']: loggedIn }">
              <default-button v-if="!loggedIn" class="mx-2" text="Inloggen" @click="$router.push({ name: 'Login' })"
                type="white" />
              <default-button v-if="loggedIn && $route.name !== 'Account'" class="mx-2" text="Account"
                @click="$router.push({ name: 'Account' })" type="gradient" />
              <default-button v-if="loggedIn && $route.name === 'Account'" class="mx-2" text="Shops"
                @click="$router.push({ name: 'Home' })" type="gradient" />
            </li>
            <li v-if="!loggedIn" class="mx-2 hide-mobile">
              <default-button class="mx-2" text="Registreren" @click="$router.push({ name: 'Registration' })"
                type="gradient" />
            </li>
          </ul>
        </nav>
      </div>
      <div v-if="$route.name !== 'Support'" class="question-mark-wrapper hide-desktop">
        <router-link :to="{ name: 'Support' }">
          <img class="question" :src="require(`@/assets/img/question-mark.png`)" alt="raboom-icon" />
        </router-link>
      </div>
      <div class="text-wrapper">
        <h1>{{ title }}
          <slot name="title" v-if="!title" />
        </h1>
        <h2>{{ subTitle }}</h2>
        <slot />
        <default-button v-if="loggedIn && $route.name !== 'Account'" class="hide-desktop mt-5" text="Account"
          @click="$router.push({ name: 'Account' })" type="gradient" />
        <default-button v-if="loggedIn && $route.name === 'Account'" class="hide-desktop mt-5" text="Shops"
          @click="$router.push({ name: 'Shops' })" type="gradient" />
        <default-button v-if="!loggedIn" class="hide-desktop mt-5" text="Registreren"
          @click="$router.push({ name: 'Registreren' })" type="gradient" />
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DefaultButton from "../elements/DefaultButton.vue";

export default {
  name: "HeaderComponent",
  components: {
    DefaultButton,
  },
  props: ["title", "subTitle", "user"],
  methods: {
    logout() {
      const { $store, $route, $router } = this;

      $store.dispatch("cashback/removeToken");

      if ($route.name !== "Home") {
        $router.push({ name: "Home" });
      }
    },
  },
  computed: {
    ...mapGetters("cashback", ["loggedIn"]),
    ...mapState("cashback", {
      transactions: (state) => state.transactions,
    }),
    totalCashback() {
      return this.transactions
        .filter((item) => item.status.toUpperCase() === "CONFIRMED")
        .reduce((acc, item) => acc + parseInt(item.amount), 0);
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$store.dispatch("cashback/getTransactions");
    }
  },
};
</script>

<style scoped>
.header {
  padding: 0 0 60px 0;
  height: 100%;
}

header {
  height: 500px;
  background: url("../../assets/img/login-header.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.logo {
  width: 218px;
}

.hide-desktop {
  display: none;
}

.text-wrapper {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  color: var(--white);
  font-family: "GT Walsheim Pro";
  font-size: var(--xl6);
  letter-spacing: 0;
  line-height: var(--xl7);
  text-align: center;
  max-width: 600px;
}

h2 {
  color: var(--white);
  font-size: var(--xl4);
  letter-spacing: 0;
  margin: 10px 0 0;
  line-height: var(--xl5);
  text-align: center;
}

p {
  height: 14px;
  width: 109px;
  color: var(--white);
  font-family: "GT Walsheim Pro";
  font-size: var(--xs);
  font-weight: bold;
  letter-spacing: 0;
  line-height: var(--sm);
  text-align: right;
}

p:last-of-type {
  margin-top: 5px;
  color: var(--white);
  font-family: "GT Walsheim Pro";
  font-size: var(--lg);
  letter-spacing: 0;
  line-height: var(--lg);
  text-align: right;
}

.question {
  height: 25px;
}

.question-mark-wrapper {
  position: absolute;
  right: 10px;
  top: 465px;
}

@media only screen and (max-width: 600px) {
  header {
    background: url("../../assets/img/login-header-small.png") no-repeat center center;
    background-size: cover;
  }

  h1 {
    font-size: var(--xl3);
    line-height: var(--xl4);
  }

  h2 {
    margin-top: 10px;
    font-size: var(--xl2);
    line-height: var(--xl3);
  }

  .header {
    padding: 15px;
    height: 100%;
  }

  .logo {
    width: 160px;
  }

  .hide-mobile {
    display: none;
  }

  .hide-desktop {
    display: block;
  }
}
</style>
