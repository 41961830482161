var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-tab"},[_c('transition',{key:"1",attrs:{"name":"fade","mode":"out-in"}},[(_vm.tabIndex === 1)?_c('div',[_c('default-text-input',{staticClass:"mt-10",attrs:{"label":"Voornaam","value":_vm.signUpCredentials.first_name,"warningLabel":"Voer een naam in","isManualValid":_vm.validations.name},on:{"inputValue":function($event){_vm.signUpCredentials.first_name = $event}}}),_c('default-text-input',{staticClass:"mt-10",attrs:{"label":"Geboortedatum","value":_vm.signUpCredentials.birthdate,"warningLabel":_vm.advanceAgeWarning,"isManualValid":_vm.validations.birthdate},on:{"inputValue":function($event){_vm.signUpCredentials.birthdate = $event}}})],1):_c('div',{key:"2"},[_c('default-radio-buttons',{attrs:{"label":"Ik zit op...","options":_vm.options},on:{"radioValue":function($event){_vm.signUpCredentials.role = $event}}}),(_vm.signUpCredentials.role)?[_c('default-text-input',{staticClass:"mt-10",attrs:{"label":_vm.emailLabels.primary,"value":_vm.signUpCredentials.email,"warningLabel":_vm.advanceEmailWarning,"isManualValid":_vm.validations.email},on:{"inputValue":function($event){_vm.signUpCredentials.email = $event}}},[_c('p',{staticClass:"warning-text",attrs:{"slot":"warning"},slot:"warning"},[_vm._v(" Dit account heeft nog geen wachtwoord, maak "),_c('router-link',{attrs:{"to":{ name: 'Recovery' }}},[_c('a',{staticClass:"link"},[_vm._v("hier")])]),_vm._v(" een wachtwoord aan. ")],1)]),(
            !_vm.signUpCredentials.role
              ? true
              : _vm.options.includes(_vm.signUpCredentials.role) &&
                _vm.signUpCredentials.role !== 'Tussenjaar'
          )?_c('default-text-input',{staticClass:"mt-10",attrs:{"warningLabel":"Voer een correcte e-mailadres in","label":_vm.emailLabels.secondary,"value":_vm.signUpCredentials.email_secondary,"isManualValid":_vm.validations.emailSecondary},on:{"inputValue":function($event){_vm.signUpCredentials.email_secondary = $event}}}):_vm._e(),_c('default-text-input',{staticClass:"mt-10",attrs:{"label":"Wachtwoord","inputType":"password","validation":_vm.isNotEmpty,"value":_vm.signUpCredentials.password,"isManualValid":_vm.validations.password,"warningLabel":"Voer een wachtwoord in"},on:{"inputValue":function($event){_vm.signUpCredentials.password = $event}}}),(!_vm.ageIsValid)?_c('default-check-box',{staticClass:"mt-10",attrs:{"value":_vm.validations.parentalConsent,"label":"Ik ben nog geen 18 jaar oud, maar heb toestemming van mijn ouders"},on:{"checkboxValue":function($event){_vm.validations.parentalConsent = $event}}}):_vm._e(),_c('default-check-box',{staticClass:"mt-5",attrs:{"value":_vm.validations.termsAgrement,"label":"Ik ga akkoord met de algemene voorwaarden en privacy beleid","warningLabel":"Je moet akkoord gaan met onze voorwaarden."},on:{"checkboxValue":function($event){_vm.validations.termsAgrement = $event}}}),_c('p',[_vm._v(" We brengen je standaard op de hoogte van jouw lopende bestellingen, opgespaard tegoed en de beste deals. "),_c('router-link',{attrs:{"to":{ name: 'Terms' }}},[_c('a',[_vm._v(" Algemene voorwaarden")])]),_vm._v(" en "),_c('router-link',{attrs:{"to":{ name: 'Privacy' }}},[_c('a',[_vm._v("privacy beleid")]),_vm._v(". ")])],1)]:_vm._e()],2)]),_c('div',{staticClass:"flex items-center mt-10",class:{
      'justify-end': _vm.tabIndex === 1,
      'justify-between': _vm.tabIndex === 2,
    },attrs:{"type":"gradient"}},[_c('default-button',{style:({ backgroundColor: '#F5F8F9' }),attrs:{"text":_vm.tabIndex === 1 ? 'Volgende stap' : 'Vorige stap',"type":_vm.tabIndex === 1 ? 'gradient' : 'gray'},on:{"click":function($event){return _vm.setTabIndex(_vm.tabIndex)}}}),(_vm.tabIndex === 2)?_c('default-button',{attrs:{"disabled":_vm.showLoader,"loading":_vm.showLoader,"type":"gradient","text":"Registreer"},on:{"click":_vm.signUp}}):_vm._e()],1),(_vm.showWarning)?_c('p',{staticClass:"warning-text text-right"},[_vm._v(" Vul het hele formulier in om "+_vm._s(_vm.tabIndex === 1 ? "verder te gaan" : "te registreren")+". ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }