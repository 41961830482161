<template>
  <div class="cashback">
    <h1>Jouw transacties</h1>
    <p>
      Je hebt totaal <b>€{{ totalCashback }}</b> cashback ontvangen over
      {{ transactions.length }} transacties
    </p>
    <ul>
      <li v-for="(transaction, i) in transactions.slice(
        0,
        slice ? 3 : transactions.length
      )" :key="i">
        <div class="content-wrapper">
          <div>
            <h2>{{ transaction.description }}</h2>
            <p>{{ formatDate(transaction.orderDate) }}</p>
          </div>
          <div class="flex items-center">
            <badge :status="transaction.status" class="mr-10 hide-mobile">{{
              buttonText[transaction.status.toUpperCase()]
            }}</badge>
            <div class="text-right">
              <h2>€{{ transaction.amount }}</h2>
              <p>over €{{ transaction.orderAmount }} excl. BTW</p>
            </div>
          </div>
        </div>
        <badge :status="transaction.status" class="mt-5 hide-desktop">
          {{ buttonText[transaction.status.toUpperCase()] }}
        </badge>
      </li>
    </ul>
    <div class="button-container">
      <default-button v-if="transactions.length" @click="slice = !slice"
        :text="slice ? 'Alle transacties tonen' : 'Toon minder transacties'" type="white-border" />

      <router-link class="router-link" :to="{ name: 'Claims' }">
        WAAR IS MIJN TRANSACTIE?
      </router-link>
    </div>

    <h1>Jouw geld</h1>
    <p>
      Je hebt <span>€{{ user ? user.wallet.value : "00.00" }}</span> in je
      portemonnee
    </p>
    <default-text-input @validate="validations.name = $event" @inputValue="accountName = $event" :validation="isNotEmpty"
      warningLabel="Voer hier je naam in zoals vermeld op je bankpas"
      placeholder="Voer hier je naam in zoals vermeld op je bankpas (bijv. J. Jansen)" class="mt-5"
      :value="accountName" />
    <default-text-input @validate="validations.iban = $event" @inputValue="accountNumber = $event"
      :validation="validateIban" warningLabel="Voer een correct IBAN nummer in"
      placeholder="Vul hier je IBAN nummer in (bijv. NL19INGB0004685678)" class="mt-5" :value="accountNumber" />
    <div class="flex justify-end items-center mt-5">
      <p class="button-caption">Binnen 5 werkdagen op de rekening</p>
      <default-button warningLabel="Voer een correcte IBAN in"
        :disabled="Object.values(validations).includes(false) || user.wallet.value === 0" @click="payoutWallet"
        text="Uitbetalen" type="gradient" @disabledClick="showWarning = true" />
    </div>
    <p v-if="showWarning" class="text-right warning-text">{{ dynamicLabel }}</p>

    <modal v-if="showPayoutModal" :close="closePayoutModal">
      <h1>Je hebt €{{ payout }} euro laten uitbetalen</h1>
      <p>
        Jouw saldo wordt zo snel mogelijk uitbetaald! Dit duurt meestal niet langer dan 3 werkdagen. Let op! Zolang je saldo niet uitbetaald is, blijft het zichtbaar in je portemonnee.
      </p>
    </modal>

    <h1>Jouw uitbetalen</h1>
    <ul>
      <li v-for="(payout, i) in payouts" :key="i">
        <p>{{ formatDate(payout.created_at) }}</p>

        <h2>€{{ payout.amount }}</h2>
      </li>
    </ul>
    <p v-if="!payouts.length">Er zijn nog geen uitbetalingen gedaan</p>
  </div>
</template>

<script>
import Badge from "../elements/Badge.vue";
import DefaultButton from "../elements/DefaultButton.vue";
import DefaultTextInput from "../elements/DefaultTextInput.vue";
import Modal from "../patterns/Modal.vue";
import { validateIban, isNotEmpty } from "../../utils/utils";
import { mapState } from "vuex";

export default {
  components: { Badge, DefaultButton, DefaultTextInput, Modal },
  name: "Cashback",
  props: ["transactions", "user", "payouts"],
  data: () => {
    return {
      buttonText: {
        CONFIRMED: "Goedgekeurd",
        REJECTED: "Afgekeurd",
        PENDING: "In behandeling",
      },
      accountNumber: "",
      accountName: "",
      slice: true,
      validations: {
        name: false,
        iban: false,
      },
      showPayoutModal: false,
      showWarning: false,
    };
  },
  methods: {
    closePayoutModal() {
      this.showPayoutModal = false;
      this.accountNumber = "";
    },

    async payoutWallet() {
      await this.$store.dispatch("cashback/payout", {accountNumber:this.accountNumber, accountName: this.accountName});
      await this.$store.dispatch("cashback/getPayouts");
      await this.$store.dispatch("cashback/getUser");

      this.showPayoutModal = true;
    },

    formatDate(date) {
      const dateObject = new Date(date);
      return `${dateObject.getDate()}-${dateObject.getMonth() +
        1}-${dateObject.getFullYear()}`;
    },

    validateIban,
    isNotEmpty,
  },
  computed: {
    ...mapState("cashback", {
      payout: (state) => state.payout,
    }),
    totalCashback() {
      return this.transactions
        .filter((item) => item.status.toUpperCase() === "CONFIRMED")
        .reduce((acc, item) => acc + parseInt(item.amount), 0);
    },
    dynamicLabel() {
      if (!this.validations.iban && this.user.wallet.value === 0) {
        return "Je hebt geen geldige IBAN ingevuld en je portemonnee is leeg";
      }

      if (!this.validations.iban) {
        return "Je hebt geen geldige IBAN ingevuld";
      }

      return "Je portemonnee is leeg";
    },
  },
};
</script>

<style scoped>
.warning-text {
  color: var(--red);
  margin-top: 10px;
  font-size: var(--m);
  font-weight: normal;
}

.text-right {
  min-width: 200px;
}

.button-container {
  padding: 40px 0;
  display: block;
  width: 100%;
  text-align: center;
}

.router-link {
  padding: 20px 0;
  display: block;
  color: rgb(29, 158, 249);
}

.button-caption {
  color: var(--gray);
  font-family: "GT Walsheim Pro";
  font-size: var(--m);
  letter-spacing: 0;
  line-height: var(--m);
  text-align: right;
  margin-right: 20px;
}

.content-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

li {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 0;
  cursor: pointer;
  justify-content: space-between;
}

li:last-of-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.hide-desktop {
  display: none;
}

h1 {
  color: var(--black);
  font-family: "GT Walsheim Pro";
  font-size: var(--xl4);
  font-weight: bold;
  letter-spacing: 0;
  line-height: var(--xl5);
}

p {
  color: var(--black);
  font-family: "GT Walsheim Pro";
  font-size: var(--xl);
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 10px;
}

p span {
  color: var(--pink);
}

li {
  display: inline-flex;
  width: 100%;
  justify-items: space-between;
}

li p {
  color: var(--gray);
  font-family: "GT Walsheim Pro";
  font-size: var(--m);
  letter-spacing: 0;
  line-height: 24px;
}

li h2 {
  color: var(--black);
  font-family: "GT Walsheim Pro";
  font-size: var(--xl);
  letter-spacing: 0;
  line-height: var(--xl);
}

ul {
  width: 100%;
  margin-top: 40px;
}

@media only screen and (max-width: 600px) {
  .hide-mobile {
    display: none;
  }

  li {
    flex-direction: column;
    align-items: flex-start;
  }

  h1 {
    margin-top: 80px;
  }

  .cashback {
    padding: 0 15px;
  }

  .hide-desktop {
    display: block;
  }
}
</style>
