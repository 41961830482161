<template>
  <div @click="goToShop($route, $router, shop)" class="px-3 cursor-pointer" v-if="shop">
    <div class="inner-card">
      <div class="top-caption" v-if="deal.type === 'promotion'">
        <span>{{ shop.title }}</span>
      </div>
      <div v-if="shop.dealType === 'promotion'" class="object-contain w-full mb-3 image"
        :style="{ backgroundImage: 'url(' + shop.imageUrl + ')' }">
        <div class="circle">
          <img :style="{ width: '60%' }" :src="shop.store.logoSquareUrl || shop.store.logoRectangularUrl"
            :alt="shop.store.logoSquareUrl || shop.store.logoRectangularUrl" />
        </div>
      </div>
      <div v-else class="logo-image" :style="{ backgroundImage: 'url(' + shop.store.logoRectangularUrl + ')' }">
      </div>
      <div class="content">
        <h1 class="mb-3">{{ shop.store.name }}</h1>
        <div class="flex items-center mb-3">
          <promo-label :cashback="shop.cashback" />
        </div>
        <p class="description">{{ shop.details }}</p>
      </div>
    </div>
  </div>
  <div @click="goToStore($route, $router, store)" class="px-3 cursor-pointer" v-else-if="store && deal">
    <div class="inner-card">
      <div class="top-caption" v-if="deal.type === 'promotion'">
        <span>{{ deal.title }}</span>
      </div>
      <div v-if="showPromotion" class="object-contain w-full mb-3 image"
        :style="{ backgroundImage: 'url(' + deal.imageUrl + ')' }">
        <div class="circle">
          <img :style="{ width: '60%' }" :src="store.logoSquareUrl || store.logoRectangularUrl"
            :alt="store.logoSquareUrl || store.logoRectangularUrl" />
        </div>
      </div>
      <img v-else :src="store.logoRectangularUrl" class="logo-image">
      <div class="content">
        <h1 class="mb-3">{{ store.name }}</h1>
        <div class="flex items-center mb-3">
          <promo-label :cashback="deal.cashback" v-if="deal" />
        </div>
        <p class="description">{{ store.shortDescription }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { goToShop, goToStore } from "../../utils/utils";
import PromoLabel from "../elements/PromoLabel.vue";

export default {
  name: "CarouselItemSmall",
  props: ["shop", "store", "showPromotion"],
  components: { PromoLabel },
  methods: {
    goToShop,
    goToStore
  },
  computed: {
    deal() {
      return this.showPromotion ? this.promotionDeal : this.defaultDeal;
    },
    promotionDeal() {
      return this.store.deals.find(deal => deal.type === "promotion");
    },
    defaultDeal() {
      const defaultDealId = this.store.percentageDefaultDealId || this.store.currencyDefaultDealId;
      if (!defaultDealId) return;
      return this.store.deals.find(deal => deal.dealId === defaultDealId);
    }
  }
};
</script>

<style scoped>
.top-caption {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--sm);
  line-height: var(--sm);
  color: var(--caption);
  padding: 10px;
  height: 50px;
  background-color: var(--caption-bg);
  transition: all 0.2s;
}

.inner-card:hover {
  position: relative;
  top: 0;
}

.inner-card:hover {
  box-shadow: 0 0 20px #ccc;
  position: relative;
  top: 10px;
  transition: all 0.2s;
}

.content {
  padding: 15px;
}

span {
  text-transform: capitalize;
}

h1 {
  font-size: var(--xl);
}

.image {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px;
  background-size: cover;
}

.logo-image {
  width: 100%;
  height: 100px;
  object-fit: scale-down;
}

.circle {
  float: right;
  height: 80px;
  width: 80px;
  background-color: var(--white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.description {
  min-height: 80px;
  font-size: var(--sm);
  color: var(--gray);
}

@media only screen and (max-width: 600px) {
  .inner-card:hover {
    box-shadow: none;
    position: relative;
    top: 0;
  }
}
</style>
