export const BASE_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const TOKEN = "token";

export const BASE_SLIDER = {
  dots: false,
  focusOnSelect: false,
  infinite: true,
  speed: 500,
  arrows: false,
};
