const BASE_URL_CMS = "https://raboom-cms.herokuapp.com"

const cms = {
  namespaced: true,
  state: {
    sections: [],
    cashbackAmounts: []
  },
  mutations: {
    setSections: (state, payload) => state.sections = payload,
    setCashbackAmounts: (state, payload) => state.cashbackAmounts = payload
  },
  actions: {
    getSections: async ({ commit }, endpoint) => {
      const res = await fetch(BASE_URL_CMS + endpoint)
      const sectionData = await res.json()
      commit('setSections', sectionData)
    },
    getCashbackAmounts: async ({ commit }, endpoint) => {
      const res = await fetch(BASE_URL_CMS + endpoint)
      const cashbackData = await res.json()
      commit('setCashbackAmounts', cashbackData)
    }
  },
  getters: {
    getSectionByName: (state) => (sectionName) => {
      return  state.sections.find(section => section.sectionName === sectionName) !== undefined
      ? state.sections.find(section => section.sectionName === sectionName).sections[0] : []
    },
    getCashbackNumbers: (state) => (sectionName) => {
      return  state.cashbackAmounts.find(section => section.sectionName === sectionName) !== undefined
      ? state.cashbackAmounts.find(section => section.sectionName === sectionName).cashbackCounter[0] : []
    }
  }
}

export default cms