<template>
  <div class="header">
    <router-link :to="{ name: 'Home' }">
      <img class="logo" src="@/assets/img/icons/logo-color.svg" alt="Logo" />
    </router-link>
    <router-link :to="{ name: 'Home' }">
      <p class="text">Terug naar website</p>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SmallHeaderComponent"
};
</script>

<style scoped>
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  color: var(--gray);
  font-size: var(--m);
  letter-spacing: 0;
  line-height: 24px;
}

@media only screen and (max-width: 600px) {
  .logo {
    width: 160px;
  }
}
</style>
