import Vue from 'vue';
import Vuex from 'vuex';
import cashback from './modules/cashback'
import cms from './modules/cms'

Vue.use(Vuex);

const store = new Vuex.Store ({
  modules: {
    cashback,
    cms
  }
});

export default store;