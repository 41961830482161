<template>
  <div :class="status.toLowerCase()">
    <slot v-if="!text" />
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "Badge",
  props: ["text", "status"]
};
</script>

<style scoped>
div {
  display: flex;
  background-color: #f5f8f9;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 28px;
  cursor: pointer;
  min-height: 32px;
}

.pending {
  background-color: rgba(29, 157, 249, 0.1);
  color: #1d9ef9;
}

.rejected {
  background-color: rgba(251, 44, 111, 0.1);
  color: var(--pink);
}

.confirmed {
  background-color: rgba(0, 185, 9, 0.1);
  color: #00b909;
}
</style>
