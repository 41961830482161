<template>
  <div class="shops-list">
    <div v-if="showFilterMenu" class="mobile-action-view hide-desktop">
      <default-text-input @inputValue="searchFilter = $event" type="search" class="mb-2 input-mobile"
        @focus="isSearching = true" @blur="isSearching = false" />
      <div class="flex justify-center items-center mb-2">
        <div @click="mode = 'GRID'" :class="{ ['icon-circle']: true, ['circle-active']: mode === 'GRID' }">
          <grid :fill="mode === 'GRID' ? '#fb2c6f' : '#7a7985'" />
        </div>
        <div @click="mode = 'LIST'" :class="{ ['icon-circle']: true, ['circle-active']: mode === 'LIST' }">
          <list :fill="mode === 'LIST' ? '#fb2c6f' : '#7a7985'" />
        </div>
      </div>
      <span @click="setCurrentCategory(category)"
        :class="{ pill: true, ['active-pill']: categoryFilter === category.categoryId }" v-for="category in categories"
        :key="category.categoryId">{{ category.name }}</span>
    </div>
    <div class="title-wrapper">
      <h1>Alle shops</h1>
      <div v-if="stickyActionBar" @click="scrollToSearchBar" :class="{
        sticky: stickyActionBar,
        ['top-button']: true,
        ['hide-mobile']: true,
      }">
        <img class="icon" src="@/assets/img/icons/up-arrow.svg" alt="arrow-up-icon" />
      </div>
      <span v-if="stickyActionBar" @click="toggleFilterMenu" :class="{
        sticky: stickyActionBar,
        ['top-button']: true,
        ['hide-desktop']: true,
      }">
        <img class="icon" :src="require(`@/assets/img/icons/${!showFilterMenu ? 'filter' : 'cross-black'}.svg`)"
          alt="arrow-up-icon" />
      </span>
      <div ref="actionBar" class="flex justify-between items-center action-wrapper hide-mobile">
        <default-text-input @inputValue="searchFilter = $event" type="search" class="not-expanded"
          :class="{ expand: isSearching || searchFilter.trim() !== '' }" @focus="isSearching = true"
          @blur="isSearching = false" />
        <div class="flex justify-center items-center">
          <div @click="mode = 'GRID'" :class="{
            ['icon-circle']: true,
            ['circle-active']: mode === 'GRID',
          }">
            <grid :fill="mode === 'GRID' ? '#fb2c6f' : '#7a7985'" />
          </div>
          <div @click="mode = 'LIST'" :class="{
            ['icon-circle']: true,
            ['circle-active']: mode === 'LIST',
          }">
            <list :fill="mode === 'LIST' ? '#fb2c6f' : '#7a7985'" />
          </div>
        </div>
      </div>
    </div>
    <div class="category-container hide-mobile">
      <span @click="setCurrentCategory(category)"
        :class="{ pill: true, ['active-pill']: categoryFilter === category.categoryId }" v-for="category in categories"
        :key="category.categoryId">{{ category.name }}</span>
    </div>
    <div v-if="mode === 'GRID'" class="grid-container" :class="{
      ['search-grid']: filteredStores.length < 4 && filteredStores.length !== 0,
    }">
      <carousel-item-small :store="store" v-for="(store, i) in !isFiltering
        ? sortedStores.slice(0, !expand ? 12 : sortedStores.length)
        : filteredStores" :key="i" />
      <div class="text-center" v-if="searchFilter.trim() !== '' && filteredStores.length === 0">
        Geen shops gevonden voor de zoekopdracht <b>"{{ searchFilter }}"</b>
      </div>
    </div>


    <ul v-else>
      <li v-for="(store, i) in !isFiltering
        ? sortedStores.slice(0, !expand ? 12 : sortedStores.length)
        : filteredStores" :key="i" class="card-wrapper" @click="goToStore($route, $router, store)">
        <div class="card-title-wrapper">
          <div class="circle hide-mobile">
            <img :style="{ width: '60%' }" :src="store.logoSquareUrl || store.logoRectangularUrl" :alt="store.name" />
          </div>
          <div class="text-wrapper">
            <div class="small-wrapper hide-desktop">
              <div class="circle-small">
                <img :style="{ width: '70%' }" :src="store.logoSquareUrl || store.logoRectangularUrl" :alt="store.name" />
              </div>
              <h2>{{ store.name }}</h2>
              <div class="small-button hide-desktop">
                <img :style="{ width: '60%' }" src="@/assets/img/icons/arrow-right.svg" alt="arrow-right" />
              </div>
            </div>
            <p>{{ store.shortDescription }}</p>
          </div>
        </div>
        <div class="caption-wrapper">
          <default-button class="hide-mobile" text="Bekijk shop" type="gray" />
        </div>
      </li>
      <li v-if="searchFilter.trim() !== '' && filteredStores.length === 0" class="text-center">
        Geen shops gevonden voor de zoekopdracht <b>"{{ searchFilter }}"</b>
      </li>
    </ul>



    <default-button></default-button>
    <div class="center-button">
      <default-button v-if="!isFiltering && (filteredStores.length !== 0 || sortedStores.length !== 0)"
        @click="toggleExpand" :text="!expand ? 'Meer shops zien' : 'Minder shops zien'" type="gradient" />
    </div>
    <div ref="shopsEnd" />
  </div>
</template>
  
<script>
import DefaultTextInput from "../elements/DefaultTextInput.vue";
import DefaultButton from "../elements/DefaultButton.vue";
import CarouselItemSmall from "@/components/patterns/CarouselItemSmall.vue";
import { goToShop, goToStore } from "../../utils/utils";
import Grid from "../svg/Grid.vue";
import List from "../svg/List.vue";
import { mapState } from "vuex";

export default {
  components: {
    DefaultTextInput,
    DefaultButton,
    CarouselItemSmall,
    Grid,
    List,
  },
  name: "StoreList",
  props: ["title", "stores"],
  mounted() {
    this.$store.dispatch("cashback/getCategories");

    this.actionBarOffset = this.$refs.actionBar.offsetTop;
    this.endShops = this.$refs.shopsEnd.offsetTop;

    window.addEventListener("scroll", () => {
      this.setStikcyActionBar();
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.setStikcyActionBar());
  },
  methods: {
    goToShop,
    goToStore,
    toggleExpand() {
      this.expand = !this.expand;

      if (!this.expand) {
        this.scrollToSearchBar();
      }

      setInterval(() => {
        this.actionBarOffset = this.$refs.actionBar.offsetTop;
        this.endShops = this.$refs.shopsEnd.offsetTop;
      }, 100);
    },

    toggleFilterMenu() {
      document.body.style.overflowY = !this.showFilterMenu
        ? "hidden"
        : "initial";

      this.showFilterMenu = !this.showFilterMenu;
    },

    setCurrentCategory({ categoryId }) {
      if (this.categoryFilter === categoryId) {
        this.categoryFilter = null;
      } else {
        this.categoryFilter = categoryId;
      }
    },

    filterStores(stores, { search, category }) {
      const trimmedSearch = search.trim().toLowerCase();

      let filteredStores = stores.filter((store) => {
        const name = store.name.toLowerCase();
        const hasCategory = category === null || store.categoryIds.includes(category);
        const matchesSearch = name.includes(trimmedSearch);

        return hasCategory && matchesSearch;
      });

      return filteredStores;
    },

    scrollToSearchBar() {
      window.scrollTo(0, this.actionBarOffset);
    },

    sortStoreAlphabetically(stores) {
      return stores.sort((a, b) => a.name.localeCompare(b.name))
    },

    setStikcyActionBar() {
      const currentScroll = document.documentElement.scrollTop;
      if (
        currentScroll > this.actionBarOffset &&
        currentScroll < this.endShops
      ) {
        return (this.stickyActionBar = true);
      } else {
        return (this.stickyActionBar = false);
      }
    },
  },
  computed: {
    ...mapState("cashback", {
      categories: (state) => state.categories,
    }),

    sortedStores() {
      return this.sortStoreAlphabetically(this.stores);
    },

    searchFilters() {
      return { search: this.searchFilter, category: this.categoryFilter };
    },

    isFiltering() {
      const { search, category } = this.searchFilters;
      return search.trim() !== "" || category !== null;
    },
  },

  data: () => {
    return {
      isSearching: false,
      searchFilter: "",
      filteredStores: [],
      mode: "GRID",
      stickyActionBar: false,
      expand: false,
      categoryFilter: null,
      actionBarOffset: null,
      endShops: null,
      showFilterMenu: false,
    };
  },

  watch: {
    searchFilters: function (val) {
      this.filteredStores = this.filterStores(this.sortedStores, val);
    },
  },
};
</script>
  
<style scoped>
.mobile-action-view {
  padding: 80px 0 60px;
  color: white;
  position: fixed;
  /* Sit on   top of the page content */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.top-button {
  padding: 10px;
  border-radius: 50%;
  border: 1px solid black;
  cursor: pointer;
  width: initial;
  background-color: var(--white);
}

.icon {
  width: 20px;
}

.category-container {
  display: block;
  padding: 10px 0;
}

.pill {
  border: 1px solid var(--caption);
  padding: 5px 10px;
  color: var(--caption);
  min-width: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  border-width: thin;
  margin: 0 5px 5px 0;
  cursor: pointer;
}

.active-pill {
  background-color: var(--caption);
  color: var(--white);
}

.grid-container {
  display: grid;
  gap: 0px 0px;
  row-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}

.search-grid {
  grid-template-columns: repeat(auto-fit, minmax(320px, 385px));
}

.not-expanded {
  width: 200px;
  transition: all 0.2s linear;
}

.expand {
  width: 400px;
  transition: all 0.2s linear;
}

.sticky {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 99999;
}

.center-button {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.icon-circle {
  display: inline-flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
  max-width: 60px;
  border-radius: 50%;
  margin-left: 20px;
  cursor: pointer;
  background-color: var(--white);
}

.circle-active {
  border-color: var(--pink);
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h1 {
  color: var(--black);
  font-family: "GT Walsheim Pro";
  font-size: var(--xl4);
  font-weight: bold;
  letter-spacing: 0;
  line-height: var(--xl5);
}

h2 {
  color: var(--black);
  font-family: "GT Walsheim Pro";
  font-size: var(--xl);
  letter-spacing: 0;
  line-height: 24px;
}

h3 {
  color: var(--pink);
  font-family: "GT Walsheim Pro";
  font-size: var(--m);
  letter-spacing: 0;
  line-height: 24px;
  text-transform: uppercase;
  margin-right: 20px;
}

p {
  color: var(--gray);
  font-family: "GT Walsheim Pro";
  font-size: var(--sm);
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 5px;
}

li {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px 0;
  cursor: pointer;
}

li:last-of-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.circle {
  width: 81px;
  height: 81px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-title-wrapper {
  display: flex;
}

.card-wrapper {
  display: flex;
  justify-content: space-between;
}

.caption-wrapper {
  display: flex;
  justify-items: center;
  align-items: center;
}

.text-wrapper {
  max-width: 600px;
  display: flex;
  align-items: center;
}

.hide-desktop {
  display: none;
}

@media only screen and (max-width: 600px) {
  .hide-mobile {
    display: none;
  }

  .pill {
    width: 90%;
  }

  .action-wrapper {
    flex-direction: column;
    width: 90%;
  }

  .hide-desktop {
    display: flex;
  }

  .card-wrapper {
    flex-direction: column;
  }

  .small-wrapper {
    display: flex;
    align-items: center;
    justify-items: space-between;
    width: 100%;
  }

  .small-button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .circle-small {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .caption-wrapper {
    margin-top: 5px;
    flex-direction: column;
    align-items: flex-start;
  }

  li:first-of-type {
    border-top: none;
  }

  .hide-mobile {
    display: none;
  }

  .title-wrapper {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .title-wrapper div {
    width: 95%;
    margin-top: 10px;
  }

  .shops-list {
    padding: 15px;
  }

  .text-wrapper {
    flex-direction: column;
  }

  .small-wrapper div:first-of-type {
    margin-right: 10px;
  }

  .small-wrapper div:last-of-type {
    margin-left: auto;
  }

  .input-mobile {
    width: 90%;
  }
}
</style>
  