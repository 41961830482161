<template>
  <div class="overlay" @click="goBack">
    <div class="shop-wrapper">

      <div v-if="selectedShop" class="content">
        <div class="image" :style="{ backgroundImage: 'url(' + selectedShop.imageUrl + ')' }">
          <router-link :to="{ name: backRoute }">
            <div class="close">
              <img src="@/assets/img/icons/cross.svg" alt="close" />
            </div>
          </router-link>
        </div>
        <h2 class="top-caption">
          {{ selectedShop.title }}
        </h2>
        <div class="text-wrapper">
          <div class="title-wrapper">
            <div>
              <h1 class="title">{{ selectedShop.store.name }}</h1>
              <p class="caption">
                <currency fill="#FB2C6F" />{{
                  cashbackLabel
                }}
              </p>
            </div>
            <img :style="{ width: '20%' }" :src="selectedShop.store.logoRectangularUrl" />
          </div>
          <p class="content-text">{{ selectedShop.details }}</p>
          <!-- <ul>
            <li class="commission-group" v-for="(group, i) in selectedShop.commission_groups" :key="i">
              <span>{{ group.name }}</span> <span> {{ group.value }}</span>
            </li>
          </ul> -->

          <div class="mt-5 text-center">
            <template v-if="loggedIn">
              <a v-if="link" :href="link" target="_blank">
                <default-button type="gradient">
                  {{ ctaLabel }} &#128513;
                </default-button>
              </a>
              <span v-else class="button-container">
                <timer :seconds="3" />
                <default-button type="gradient" :disabled="true">
                  {{ ctaLabel }} &#128513;
                </default-button>
              </span>
            </template>
            <router-link v-else :to="{ name: 'Login' }">
              <default-button type="gradient">Eerst Inloggen &#128073;</default-button>
            </router-link>
            <p class="calculation">
              De cashback wordt berekend over het bedrag excl. BTW
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DefaultButton from "../components/elements/DefaultButton.vue";
import Currency from "../components/svg/Currency.vue";
import { mapGetters, mapActions } from "vuex";
import Timer from "../components/patterns/Timer.vue";

export default {
  name: "Shop",
  mounted() {
    document.body.style.overflowY = "hidden";
  },
  destroyed() {
    document.body.style.overflowY = "initial";
  },

  metaInfo() {
    return {
      titleTemplate: `%s | ${this.selectedShop?.store.name}`,
      description: this.selectedShop?.details,
    };
  },
  components: { DefaultButton, Currency, Timer },
  data() {
    return {
      link: "",
    };
  },
  methods: {
    ...mapActions("cashback", ["getTrackingLink", "trackShopView"]),
    goBack(e) {
      if (this.$el === e.target) {
        this.$router.push({ name: this.backRoute });
      }
    },
  },
  computed: {
    ...mapGetters("cashback", ["loggedIn"]),
    selectedShop() {
      const { $store, $route } = this;
      return $store.state.cashback.shops.find(

        (shop) => {
          return shop.urlPathSegment === $route.params.id
        }
      );
    },

    ctaLabel() {
      const cashbackType = this.selectedShop.program_type;

      return cashbackType === "cashback_voucher"
        ? "Krijg cashback + kortingscode"
        : cashbackType === "discount"
          ? "Krijg directe korting"
          : cashbackType === "discount_voucher"
            ? "Krijg directe korting met code"
            : "Krijg cashback";
    },

    cashbackLabel() {
      if (!this.selectedShop.cashback) return "";
      const type = this.selectedShop.cashback.type;

      if (type === "percentage") {
        return `${this.selectedShop.cashback.percentage}% cashback`;
      } else if (type === "amount" || type === "currency") {
        const value = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(this.selectedShop.cashback.amount);
        return `${value} cashback`;
      } else {
        return "";
      }
    },

    backRoute() {
      if (this.$route) {
        const path = this.$route.fullPath.split("/")[1];
        const routeName = path === "registreren" ? "registration" : path;
        return routeName.charAt(0).toUpperCase() + routeName.slice(1);
      }
      return "Home";
    },
  },

  async created() {
    await this.$store.dispatch("cashback/getStores");
    if (this.loggedIn) {
      // this.trackShopView(this.selectedShop.id);
      this.link = await this.getTrackingLink(this.selectedShop.dealId);
    }
  },
};
</script>

<style scoped>
.shop-wrapper {
  overflow: scroll;
  height: 100%;
  margin-top: 10vh;
}

.commission-group {
  color: black;
  display: flex;
  justify-content: space-between;
}

.button-container {
  display: flex;
  justify-content: center;
}

ul {
  padding: 20px 20px 10px;
}

.commission-group span:last-of-type {
  color: var(--pink);
}

.overlay {
  color: white;
  position: fixed;
  /* Sit on   top of the page content */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  background-color: var(--black);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.caption {
  color: var(--pink);
  font-family: "GT Walsheim Pro";
  font-size: var(--sm);
  letter-spacing: 0;
  line-height: 16px;
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.caption svg {
  margin-right: 10px;
}

.caption svg g {
  fill: #fb2c6f;
}

.content {
  overflow: scroll;
  width: 535px;
  background-color: white;
}

.text-wrapper {
  padding: 40px;
}

.calculation {
  color: var(--gray);
  font-family: "GT Walsheim Pro";
  font-size: var(--xs);
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 10px;
}

.content-text {
  margin-top: 24px;
  color: var(--gray);
  font-family: "GT Walsheim Pro";
  font-size: var(--m);
  letter-spacing: 0;
  line-height: 24px;
}

.title {
  height: 40px;
  color: var(--black);
  font-family: "GT Walsheim Pro";
  font-size: var(--xl4);
  font-weight: bold;
  letter-spacing: 0;
  line-height: var(--xl5);
}

.top-caption {
  text-align: center;
  font-size: var(--sm);
  line-height: var(--sm);
  color: var(--caption);
  padding: 10px;
  background-color: rgb(162, 215, 253);
  text-transform: capitalize;
}

.image {
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  background-size: contain;
}

@media only screen and (max-width: 600px) {
  .content {
    height: 100%;
    width: 100%;
    background-color: white;
  }

  .title-wrapper {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .title {
    margin-top: 20px;
  }

  .text-wrapper {
    padding: 15px;
  }

  .calculation {
    margin: 10px auto 0;
    max-width: 50%;
  }

  .image {
    height: 35%;
    background-size: cover;
  }
}
</style>
