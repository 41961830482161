<template>
  <div class="carousel">
    <div class="flex items-center justify-between">
      <span class="flex items-center carousel-title">
        <h1>{{ title ? title : undefined }}</h1>
      </span>
      <div class="inline-flex">
        <img class="arrow mr-3 hide-mobile" @click="showPrevious" src="@/assets/img/icons/arrow-left.svg"
          alt="arrow-left" />
        <img class="arrow hide-mobile" @click="showNext" src="@/assets/img/icons/arrow-right.svg" alt="arrow-right" />
      </div>
    </div>
    <div class="inner-carousel">
      <vue-slick-carousel ref="carousel" v-bind="settings">
        <slot />
      </vue-slick-carousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Carousel",
  props: ["title", "settings"],
  components: {
    VueSlickCarousel
  },
  methods: {
    showNext() {
      this.$refs.carousel.next();
    },
    showPrevious() {
      this.$refs.carousel.prev();
    }
  }
};
</script>

<style scoped>
.carousel-title {
  font-size: var(--xl4);
  font-weight: bold;
  margin-bottom: 20px;
}

.carousel-title a {
  font-size: var(--m);
  font-weight: normal;
}

.carousel {
  padding: 40px 0 40px 0;
  width: 100%;
}

.arrow {
  cursor: pointer;
  height: 30px;
  width: 30px;
}

@media only screen and (max-width: 600px) {
  .hide-mobile {
    display: none;
  }

  .carousel-title {
    display: block;
    margin-left: 15px;
  }

  .carousel-title p {
    margin-left: 0;
    position: relative;
    bottom: 5px;
  }

  .carousel {
    padding: 40px 0 0;
    width: 100%;
    overflow: hidden;
  }

  .inner-carousel {
    width: 180%;
  }
}
</style>
