var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4, _obj$5, _obj$6;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shops-list"},[(_vm.showFilterMenu)?_c('div',{staticClass:"mobile-action-view hide-desktop"},[_c('default-text-input',{staticClass:"mb-2 input-mobile",attrs:{"type":"search"},on:{"inputValue":function($event){_vm.searchFilter = $event},"focus":function($event){_vm.isSearching = true},"blur":function($event){_vm.isSearching = false}}}),_c('div',{staticClass:"flex justify-center items-center mb-2"},[_c('div',{class:( _obj = {}, _obj['icon-circle'] = true, _obj['circle-active'] = _vm.mode === 'GRID', _obj ),on:{"click":function($event){_vm.mode = 'GRID'}}},[_c('grid',{attrs:{"fill":_vm.mode === 'GRID' ? '#fb2c6f' : '#7a7985'}})],1),_c('div',{class:( _obj$1 = {}, _obj$1['icon-circle'] = true, _obj$1['circle-active'] = _vm.mode === 'LIST', _obj$1 ),on:{"click":function($event){_vm.mode = 'LIST'}}},[_c('list',{attrs:{"fill":_vm.mode === 'LIST' ? '#fb2c6f' : '#7a7985'}})],1)]),_vm._l((_vm.categories),function(category){
var _obj;
return _c('span',{key:category.categoryId,class:( _obj = { pill: true }, _obj['active-pill'] = _vm.categoryFilter === category.categoryId, _obj ),on:{"click":function($event){return _vm.setCurrentCategory(category)}}},[_vm._v(_vm._s(category.name))])})],2):_vm._e(),_c('div',{staticClass:"title-wrapper"},[_c('h1',[_vm._v("Alle shops")]),(_vm.stickyActionBar)?_c('div',{class:( _obj$2 = {
      sticky: _vm.stickyActionBar
    }, _obj$2['top-button'] = true, _obj$2['hide-mobile'] = true, _obj$2 ),on:{"click":_vm.scrollToSearchBar}},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/icons/up-arrow.svg"),"alt":"arrow-up-icon"}})]):_vm._e(),(_vm.stickyActionBar)?_c('span',{class:( _obj$3 = {
      sticky: _vm.stickyActionBar
    }, _obj$3['top-button'] = true, _obj$3['hide-desktop'] = true, _obj$3 ),on:{"click":_vm.toggleFilterMenu}},[_c('img',{staticClass:"icon",attrs:{"src":require(("@/assets/img/icons/" + (!_vm.showFilterMenu ? 'filter' : 'cross-black') + ".svg")),"alt":"arrow-up-icon"}})]):_vm._e(),_c('div',{ref:"actionBar",staticClass:"flex justify-between items-center action-wrapper hide-mobile"},[_c('default-text-input',{staticClass:"not-expanded",class:{ expand: _vm.isSearching || _vm.searchFilter.trim() !== '' },attrs:{"type":"search"},on:{"inputValue":function($event){_vm.searchFilter = $event},"focus":function($event){_vm.isSearching = true},"blur":function($event){_vm.isSearching = false}}}),_c('div',{staticClass:"flex justify-center items-center"},[_c('div',{class:( _obj$4 = {}, _obj$4['icon-circle'] = true, _obj$4['circle-active'] = _vm.mode === 'GRID', _obj$4 ),on:{"click":function($event){_vm.mode = 'GRID'}}},[_c('grid',{attrs:{"fill":_vm.mode === 'GRID' ? '#fb2c6f' : '#7a7985'}})],1),_c('div',{class:( _obj$5 = {}, _obj$5['icon-circle'] = true, _obj$5['circle-active'] = _vm.mode === 'LIST', _obj$5 ),on:{"click":function($event){_vm.mode = 'LIST'}}},[_c('list',{attrs:{"fill":_vm.mode === 'LIST' ? '#fb2c6f' : '#7a7985'}})],1)])],1)]),_c('div',{staticClass:"category-container hide-mobile"},_vm._l((_vm.categories),function(category){
        var _obj;
return _c('span',{key:category.categoryId,class:( _obj = { pill: true }, _obj['active-pill'] = _vm.categoryFilter === category.categoryId, _obj ),on:{"click":function($event){return _vm.setCurrentCategory(category)}}},[_vm._v(_vm._s(category.name))])}),0),(_vm.mode === 'GRID')?_c('div',{staticClass:"grid-container",class:( _obj$6 = {}, _obj$6['search-grid'] = _vm.filteredStores.length < 4 && _vm.filteredStores.length !== 0, _obj$6 )},[_vm._l((!_vm.isFiltering
      ? _vm.sortedStores.slice(0, !_vm.expand ? 12 : _vm.sortedStores.length)
      : _vm.filteredStores),function(store,i){return _c('carousel-item-small',{key:i,attrs:{"store":store}})}),(_vm.searchFilter.trim() !== '' && _vm.filteredStores.length === 0)?_c('div',{staticClass:"text-center"},[_vm._v(" Geen shops gevonden voor de zoekopdracht "),_c('b',[_vm._v("\""+_vm._s(_vm.searchFilter)+"\"")])]):_vm._e()],2):_c('ul',[_vm._l((!_vm.isFiltering
      ? _vm.sortedStores.slice(0, !_vm.expand ? 12 : _vm.sortedStores.length)
      : _vm.filteredStores),function(store,i){return _c('li',{key:i,staticClass:"card-wrapper",on:{"click":function($event){return _vm.goToStore(_vm.$route, _vm.$router, store)}}},[_c('div',{staticClass:"card-title-wrapper"},[_c('div',{staticClass:"circle hide-mobile"},[_c('img',{style:({ width: '60%' }),attrs:{"src":store.logoSquareUrl || store.logoRectangularUrl,"alt":store.name}})]),_c('div',{staticClass:"text-wrapper"},[_c('div',{staticClass:"small-wrapper hide-desktop"},[_c('div',{staticClass:"circle-small"},[_c('img',{style:({ width: '70%' }),attrs:{"src":store.logoSquareUrl || store.logoRectangularUrl,"alt":store.name}})]),_c('h2',[_vm._v(_vm._s(store.name))]),_c('div',{staticClass:"small-button hide-desktop"},[_c('img',{style:({ width: '60%' }),attrs:{"src":require("@/assets/img/icons/arrow-right.svg"),"alt":"arrow-right"}})])]),_c('p',[_vm._v(_vm._s(store.shortDescription))])])]),_c('div',{staticClass:"caption-wrapper"},[_c('default-button',{staticClass:"hide-mobile",attrs:{"text":"Bekijk shop","type":"gray"}})],1)])}),(_vm.searchFilter.trim() !== '' && _vm.filteredStores.length === 0)?_c('li',{staticClass:"text-center"},[_vm._v(" Geen shops gevonden voor de zoekopdracht "),_c('b',[_vm._v("\""+_vm._s(_vm.searchFilter)+"\"")])]):_vm._e()],2),_c('default-button'),_c('div',{staticClass:"center-button"},[(!_vm.isFiltering && (_vm.filteredStores.length !== 0 || _vm.sortedStores.length !== 0))?_c('default-button',{attrs:{"text":!_vm.expand ? 'Meer shops zien' : 'Minder shops zien',"type":"gradient"},on:{"click":_vm.toggleExpand}}):_vm._e()],1),_c('div',{ref:"shopsEnd"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }