<template>
  <div @click="setValue" class="flex cursor">
    <div :class="{ active: value }" class="checkbox mr-3">
      <div v-if="value" class="inner-box" />
    </div>
    <label class="cursor">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: "DefaultRadioButton",
  props: ["label"],
  data: () => {
    return {
      value: false
    };
  },
  methods: {
    setValue() {
      this.value = !this.value;
      this.$emit("checkboxValue", this.value);
    }
  }
};
</script>

<style scoped>
.checkbox {
  box-sizing: border-box;
  height: 25px;
  min-width: 25px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: var(--white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox:hover {
  border: 1px solid #1d9ef9;
  background-color: #f5f8f9;
}

.cursor {
  cursor: pointer ;
}

.active {
  border: 1px solid #1d9ef9;
  background-color: #f5f8f9;
}

.inner-box {
  height: 10px;
  min-width: 10px;
  background-color: #1d9ef9;
  border-radius: 50%;
}

label {
  color: var(--gray);
  font-family: "GT Walsheim Pro";
  font-size: var(--m);
  line-height: 24px;
}
</style>
