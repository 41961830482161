<template>
  <fragment>
    <dynamic-header title="Privacy Beleid" :user="user" :loggedIn="loggedIn" />
    <div class="container mx-auto py-20">
      <div class="privacy" v-html="content" />
    </div>
    <footer-component />
  </fragment>
</template>

<script>
import DynamicHeader from "../components/patterns/DynamicHeader.vue";
import FooterComponent from "../components/patterns/FooterComponent.vue";
import { mapState, mapGetters } from "vuex";
import { PRIVACY } from "../data/privacy";

export default {
  name: "Privacy",
  components: { FooterComponent, DynamicHeader },
  metaInfo: {
    titleTemplate: "%s | Privacy Beleid"
  },
  computed: {
    ...mapState("cashback", {
      user: state => state.user
    }),
    ...mapGetters("cashback", ["loggedIn"]),

    content() {
      return PRIVACY.html;
    }
  },

  beforeMount() {
    if (this.loggedIn) {
      this.$store.dispatch("cashback/getUser");
    }
  }
};
</script>

<style>
.privacy h1 {
  font-size: var(--xl5);
  line-height: var(--xl6);
  margin: 0 0 20px 0;
}

.privacy h1:last-of-type {
  margin: 40px 0 20px 0;
}

.privacy h2 {
  font-size: var(--xl2);
  line-height: var(--xl3);
  margin: 0 0 20px 0;
}

.privacy p {
  margin: 20px 0;
}

.privacy a {
  color: var(--caption);
}

@media only screen and (max-width: 600px) {
  .privacy h1 {
    font-size: var(--xl3);
    line-height: var(--xl4);
    margin: 0 0 20px 0;
  }

  .privacy h2 {
    font-size: var(--xl1);
    line-height: var(--xl2);
    margin: 0 0 20px 0;
  }

  .privacy {
    padding: 15px;
  }
}
</style>
