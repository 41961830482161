<template>
  <fragment>
    <hero-image>
      <h1 class="title">Wachtwoord opnieuw instellen</h1>
      <default-text-input @validate="validations.password = $event" @inputValue="password = $event" inputType="password"
        :validation="validatePassword" warningLabel="Voer een wachtwoord in" :value="password"
        label="Nieuw wachtwoord" />
      <default-text-input class="mt-10" @validate="validations.password_confirmation = $event" inputType="password"
        @inputValue="password_confirmation = $event" :validation="validatePassword"
        warningLabel="Voer een wachtwoord in" :value="password_confirmation" label="Nieuw wachtwoord herhalen" />
      <modal v-if="showModal" :close="closeModal">
        <h1>
          Wachtwoord opnieuw ingesteld<br />
        </h1>
        <p>
          Je kunt nu inloggen met je nieuwe wachtwoord.
        </p>
      </modal>
      <div class="flex justify-end items-center mt-10">
        <default-button :disabled="!validations.password || !validations.password_confirmation" text="Versturen"
          @click="updatePassword" type="gradient" />
      </div>
    </hero-image>
    <footer-component :sticky="true" />
  </fragment>
</template>

<script>
import DefaultButton from "../components/elements/DefaultButton.vue";
import DefaultTextInput from "../components/elements/DefaultTextInput.vue";
import HeroImage from "../components/patterns/HeroImage.vue";
import FooterComponent from "../components/patterns/FooterComponent.vue";
import { validatePassword } from "../utils/utils";
import Modal from "../components/patterns/Modal.vue";

export default {
  components: {
    DefaultTextInput,
    HeroImage,
    DefaultButton,
    FooterComponent,
    Modal,
  },
  metaInfo: {
    titleTemplate: "%s | Wachtwoord herstellen",
  },
  data: () => {
    return {
      password: "",
      password_confirmation: "",
      validations: {
        password: false,
        password_confirmation: false,
      },
      showModal: false,
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
  },
  methods: {
    validatePassword,
    closeModal() {
      this.showModal = false;
      this.password = this.password_confirmation = "";
      validatePassword
    },

    async updatePassword() {
      const response = await this.$store.dispatch("cashback/updatePassword", { password: this.password, token: this.token });
      if (!response.ok) return;
      this.showModal = true;
    },
  },
};
</script>

<style scoped>
.title {
  margin: 80px 0;
  color: var(--black);
  font-family: "GT Walsheim Pro";
  font-size: var(--xl5);
  letter-spacing: 0;
  line-height: var(--xl4);
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .title {
    margin: 60px 0;
    font-size: var(--xl4);
    line-height: var(--xl3);
  }
}
</style>
