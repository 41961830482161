export const PRIVACY = {
  html: `
  <div class="privacy">
  <h1>Privacy Beleid</h1>
  <h2>IN HET KORT</h2>

<h4>Welke gegevens slaan we op?</h4>

<p><strong>Geanonimiseerde gegevens</strong><br>
Als je ons platform bezoekt, verwerken we gegevens van je.</p>

<p>• IP-Adres<br>
• Bezochte pagina’s<br>
• Bezoekduur<br>
• Locatie (stad, land, taal)<br>
• Browser (zoals Internet Explorer of Google Chrome)<br>
• Klikgedrag</p>

<p>Dit is geanonimiseerde data die wordt gebruikt voor statistische doeleinden. </p>

<p><strong>Persoonsgegevens</strong><br>
Om gebruik te kunnen maken van RABOOM moet je eerst een profiel aanmaken op onze website. Met dit profiel kun je inloggen op het platform en kun je je gegevens beheren. Voor het aanmaken van jouw profiel verwerken we alleen gegevens die noodzakelijk zijn, zoals je:</p>

<p>• Geboortedatum<br>
• E-mailadres<br>
• Onderwijsniveau<br>
• IBAN-rekeningnummer (alleen bij aanvragen uitbetaling credits)</p>

<p>Deze gegevens worden vijf jaar na jouw laatste login automatisch verwijderd. </p>

<p><strong>Transactiedetails</strong><br>
Voor het beheren van jouw profiel verwerken we ook transactiedetails zoals:<br>
• Aankoopbedrag<br>
• Transactiedatum en –tijd <br>
• Partner waar je je aankoop hebt gedaan</p>

<p>Deze gegevens worden voor de boekhouding maximaal zeven jaar bewaard en worden geanonimiseerd op het moment dat je profiel door ons wordt verwijderd.</p>

<p><strong>E-mailberichten</strong><br>
Als je een vraag, klacht of verzoek hebt, wordt deze opgeslagen in onze e-maildatabase. Je e-mails bewaren we om je sneller te kunnen helpen bij een vervolgvraag. Binnen zes maanden na ontvangst worden e-mails inclusief bijlagen door ons verwijderd.</p>

<p><strong>Marketing</strong><br>
Als je bij ons een profiel hebt aangemaakt, kunnen we je voor marketingdoeleinden benaderen. Dit doen wij via verschillende kanalen, maar vooral per e-mail. Jouw gegevens kunnen we combineren met gegevens van derden. Door je aankoopgedrag te analyseren kunnen we onze website, communicatiemiddelen en aanbiedingen nog beter afstemmen op jouw persoonlijke voorkeuren. Hiervoor maken we gebruik van cookiegegevens, accountgegevens en transactiedetails. Daarnaast gebruiken we je e-mailadres om je systeemberichten toe te sturen om je te informeren.</p>

<h4>Welke rechten heb je?</h4>

<p><strong>Recht op inzage</strong><br>
Je kunt op ieder moment een verzoek indienen om de persoonsgegevens die we van jou hebben, in te zien. Mits jouw identiteit kan worden vastgesteld, zullen wij binnen 4 weken kosteloos gehoor geven aan jouw verzoek. <br>
Verzoek indienen via het <a href="https://raboom.nl/support" rel="nofollow" target="_blank">contactformulier</a>.</p>

<p><strong>Recht op beperking</strong><br>
Je kunt op ieder moment een verzoek indienen om jouw persoonsgegevens tijdelijk niet te verwerken.<br>
Verzoek indienen via het <a href="https://raboom.nl/support" rel="nofollow" target="_blank">contactformulier</a>.</p>

<p><strong>Recht op wijziging</strong><br>
Je kunt bij ons op ieder moment een verzoek indienen om jouw persoonsgegevens te wijzigen. Mits jouw identiteit kan worden vastgesteld, zullen wij binnen vier weken kosteloos gehoor geven aan jouw verzoek.<br>
Verzoek indienen via het <a href="https://raboom.nl/support" rel="nofollow" target="_blank">contactformulier</a>.</p>

<p><strong>Recht om vergeten te worden</strong><br>
In sommige situaties heb je het recht om bepaalde persoonsgegevens uit onze bestanden te laten verwijderen. <br>
• Jouw gegevens niet meer nodig zijn voor de doelen waarvoor ze zijn verzameld. Bijvoorbeeld wanneer jij jouw account vijf jaar niet meer hebt gebruikt.<br>
• Wanneer jij toestemming tot het gebruik van jouw persoonsgegevens intrekt.<br>
• Wanneer wij jouw gegevens gebruiken voor andere doeleinden dan waarvoor je ze hebt opgegeven.<br>
Verzoek indienen via het <a href="https://raboom.nl/support" rel="nofollow" target="_blank">contactformulier</a>.</p>

<h1>UITGEBREIDE Privacy Beleid</h1>

<p>RABOOM.nl neemt jouw privacy zeer serieus en zal informatie over jou op een veilige manier verwerken en gebruiken. Om jouw privacy te waarborgen, handelt RABOOM altijd in overeenstemming met de wet bescherming persoonsgegevens. Op deze pagina staat het Privacy Beleid van RABOOM. We raden je aan dit Privacy Beleid aandachtig door te nemen. De met hoofdletters geschreven woorden in dit Privacy Beleid zijn gedefinieerd in Gebruikersvoorwaarden.</p>

<p><strong>Wie zit er achter <a href="http://" rel="nofollow" target="_blank">www.raboom.nl</a>?</strong></p>

<p>www.raboom.nl (RABOOM) is onderdeel van de besloten vennootschap Knaek B.V., kantoorhoudende te (1057GB) Amsterdam, aan de Admiralengracht 60H en ingeschreven in het register van de Kamer van Koophandel onder nummer 54953979; Bereikbaar via de contactpagina van RABOOM.</p>
<p>Welke informatie wordt door RABOOM verzameld en verwerkt?</p>

<p><strong>Profiel</strong></p>

<p>Om Credits te kunnen verdienen moet je een persoonlijk Profiel aanmaken. Wanneer je zelf een dergelijk Profiel creëert, wordt aan je gevraagd bepaalde informatie over jezelf in te vullen. Daarbij wordt je gevraagd om een geldig e-mailadres en je geboortedatum te verstrekken. <br>
De inhoud van jouw Profiel is niet zichtbaar voor andere Gebruikers of Bezoekers. </p>

<p><strong>Betalingsgegevens</strong></p>

<p>Op het moment dat je Credits hebt verzameld door Aankopen te doen, kun je deze door RABOOM laten uitkeren. Op dat moment vragen wij je om jouw betalingsgegevens aan ons door te geven, zodat wij deze betaling zo snel mogelijk kunnen uitvoeren. In dat kader zullen we je vragen om je bankgegevens. Deze gegevens zullen we nooit voor een ander doel gebruiken dan het overmaken van de korting die jij bij ons hebt verdiend.</p>

<p><strong>Automatisch gegenereerde informatie</strong></p>

<p>Om RABOOM optimaal te kunnen laten werken (bijvoorbeeld om pagina&#39;s goed te kunnen weergeven en om de Dienst te beveiligen) heeft RABOOM bepaalde informatie nodig. RABOOM verzamelt daarom automatisch gegenereerde informatie over je surfgedrag tijdens jouw gebruik van RABOOM. Deze informatie bestaat uit je IP-adres (nummer van je computer dat het mogelijk maakt jouw computer te herkennen), het type browser (computerprogramma om internetpagina&#39;s mee te kunnen bekijken), het computersysteem dat je gebruikt, en de pagina&#39;s die je op de Website bezoekt.<br>
Voor zover RABOOM deze informatie gebruikt, is die altijd geanonimiseerd.</p>

<p><strong>Wat zijn cookies en hoe gebruikt RABOOM ze?</strong></p>

<p>Voor de correcte werking van de Dienst van RABOOM is het gebruik van cookies essentieel. Door gebruik van RABOOM kunnen zulke cookies op jouw randapparatuur worden geplaatst, en kan toegang tot die cookies worden verkregen. Cookies zijn kleine stukjes (tekst) informatie die een server naar de browser (bijvoorbeeld Internet Explorer of Firefox) stuurt met de bedoeling dat de browser deze informatie bij een volgend bezoek weer naar de server terug zal sturen. Cookies van RABOOM kunnen je randapparatuur of de bestanden daarop niet beschadigen.</p>

<p>De onderstaande typen cookies kunnen door jouw gebruik van de Dienst op jouw randapparatuur geïnstalleerd worden:</p>

<ul style="list-style-type: disc;list-style-position: inside;">
  <li>RABOOM plaatst in de eerste plaats zelf functionele cookies. Deze cookies zijn noodzakelijk om de Dienst op een gebruiksvriendelijke wijze te kunnen leveren. Zo zorgen functionele cookies ervoor dat je na het inloggen op de website niet op elke pagina opnieuw hoeft in te loggen en onthouden deze functionele cookies de op de website ingevulde (bestel)formulieren.</li>
  <li>Naast functionele cookies plaatst RABOOM cookies die slechts een beperkte tijd op jouw randapparatuur worden opgeslagen. Zodra je de webbrowser sluit, worden deze cookies automatisch verwijderd. Deze zogenaamde sessie cookies worden gebruikt om jouw surfgedrag op de website tijdens een bepaald bezoek in kaart te brengen. RABOOM kan met behulp van deze gegevens de website aanpassen aan de behoeftes van de bezoekers.</li>
  <li>Ook plaatst RABOOM cookies die voor een 30 tot 60 dagen op jouw randapparatuur blijven staan. Aan de hand van deze zogenaamde permanente cookies wordt jouw randapparatuur bij een volgend bezoek aan de website herkend. Deze cookies bieden RABOOM de mogelijkheid om je de website te tonen die specifiek aan jouw voorkeuren is aangepast. Doordat jouw voorkeuren reeds zijn ingesteld, bespaar je bij een nieuw bezoek aan de website tijd en is het gebruik daarvan aangenamer.</li>
  <li>Daarnaast gebruikt RABOOM permanente cookies om jouw surfgedrag te volgen. Met behulp van deze zogenaamde tracking cookies registreert RABOOM jouw surfgedrag op de Website om op basis daarvan specifiek op jouw gerichte aanbiedingen te kunnen doen. Ook is het mogelijk dat door middel van tracking cookies wordt bijgehouden welke andere websites je naast deze Website bezoekt.</li>
</ul>

<p><strong>Cookies van derde partijen</strong></p>

<p>Buiten de cookies die RABOOM zelf plaatst, worden er via de website ook cookies van derde partijen geplaatst op jouw randapparatuur. Deze paragraaf heeft betrekking op deze zogenaamde third party cookies. RABOOM heeft geen controle of zeggenschap over het gebruik van deze cookies en/of het gebruik dat de derde partijen maken van de door middel van de cookies verzamelde informatie. RABOOM biedt alleen het platform dat het plaatsen van deze third party cookies mogelijk maakt. RABOOM wijst erop dat op het gebruik van de verzamelde gegevens nadere voorwaarden van de derde partijen van toepassing kunnen zijn. Hieronder wordt aangegeven welke soorten third party cookies via de Website worden geplaatst.</p>

<p><strong>Affiliate cookies</strong></p>

<p>Om in aanmerking te komen voor Credits die op jouw Profiel worden gestort, is het essentieel dat de Partner waar je jouw Aankoop doet kan zien dat je via onze Website op de website van deze Partner terecht bent gekomen. Om deze informatie aan de partner te verstrekken wordt de informatie uit zogenaamde affiliate cookies gebruikt. Dergelijke cookies verwijzen slechts naar de informatie van RABOOM, waardoor de Partner kan zien dat je van onze Website afkomstig bent. Slechts als er na het doen van een Aankoop dergelijke affiliate cookies in jouw browser worden aangetroffen, kom je in aanmerking voor het verkrijgen van Credits.</p>

<p><strong>Verwijderen cookies</strong></p>

<p>Je kunt je browser zo instellen dat geïnstalleerde cookies worden verwijderd en dat je tijdens je volgende gebruik van RABOOM geen cookies ontvangt. In dat geval kun je echter niet van alle mogelijkheden van RABOOM gebruik maken. Zo kun je geen Credits verzamelen indien je de installatie van affiliate cookies verhindert. Daarnaast kan het zo zijn dat je geen toegang hebt tot (onderdelen van) RABOOM. Om meer over deze functies te weten te komen, kun je het beste je browser instructies of de Help functie van je browser raadplegen.<br>
Als je gebruik wilt maken van cookies, zorg er dan voor dat je uitlogt indien je een openbare computer verlaat. Houd ook je wachtwoord geheim om te voorkomen dat anderen, zonder jouw toestemming, gebruik maken van jouw Profiel.</p>

<p>Voor welke doeleinden zal RABOOM informatie over jou gebruiken?</p>

<p><strong>Doeleinden</strong></p>

<p>RABOOM zal informatie over jou voor de volgende doeleinden gebruiken:</p>
<ul style="list-style-type: disc;list-style-position: inside;">
<li>Om jouw gebruik van de Dienst mogelijk te maken, namelijk het aanmaken van een Profiel en het verdienen van Credits;</li>
<li>Om betalingen aan jou mogelijk te maken;</li>
<li>Om je informatie toe te zenden omtrent de eigen diensten van RABOOM;</li>
<li>Om geanonimiseerde statistische gegevens op te stellen;</li>
<li>Om de Website te beveiligen, aan te passen en te verbeteren;</li>
<li>RABOOM wil je op de hoogte houden van de ontwikkeling van haar Dienst. Indien er bijvoorbeeld een uitbreiding komt of er sprake is van een update zal RABOOM dat daarom aan jou laten weten. Ook kan RABOOM je op de hoogte houden van acties die door haar zijn en/of worden georganiseerd;</li>
<li>Een mogelijkheid je af te melden voor onze e-mails is in elke e-mail die door RABOOM aan jou wordt gestuurd opgenomen.</li>
</ul>

<p><strong>Gebruik door derden</strong></p>

<p>Zonder jouw expliciete toestemming zal RABOOM jouw persoonsgegevens niet aan derde partijen verstrekken voor direct marketing doeleinden (bijvoorbeeld voor het toezenden van aanbiedingen).<br>
RABOOM kan jouw anonieme gegevens wel verstrekken aan derden voorzover je zelf uitdrukkelijk met betrekking tot een bepaalde derde aangegeven hebt dat je daarvoor toestemming geeft en/of voorzover deze gegevens niet tot jou persoonlijk kunnen worden herleid (zoals automatisch gegenereerde informatie, niet zijnde je IP-adres). Ten slotte kan RABOOM jouw gegevens aan derden verstrekken indien zij daartoe op basis van wet- en/of regelgeving verplicht is, zij daartoe genoodzaakt is als gevolg van een rechtszaak en/of in het geval zij dat noodzakelijk acht ter bescherming van haar eigen rechten.</p>

<p>De Website kan hyperlinks bevatten waarmee je de Website van RABOOM verlaat en op de website van een andere partij terecht komt. RABOOM heeft geen zeggenschap over diensten en/of websites van derden waarnaar wordt gelinkt. Het kan dus zo zijn dat op het gebruik van deze diensten en/of websites van derden een ander Privacy Beleid en/of voorwaarden van toepassing is/zijn. Dit Privacy Beleid van RABOOM heeft alleen betrekking op (persoons)gegevens die via de Dienst zijn verkregen. RABOOM accepteert geen enkele verantwoordelijkheid of aansprakelijkheid voor (de werking en/of inhoud van) diensten en/of websites van derden.</p>

<p><strong>Op welke wijze beschermt RABOOM jouw persoonlijke informatie?</strong></p>
<p>RABOOM zal passende technische en organisatorische maatregelen nemen om jouw (persoons-)gegevens te beveiligen tegen verlies of tegen enige vorm van onrechtmatige verwerking.</p>

<p><strong>Mogen minderjarigen gebruik maken van RABOOM?</strong></p>

<p>Als je nog geen zestien (16) jaar oud bent, dan moet je toestemming hebben van je ouders of voogd voordat je een Profiel aanmaakt. Door deze Privacy Beleid te accepteren, garandeer je dat je zestien (16) jaar of ouder bent of toestemming van je ouders/voogd hebt om een Profiel aan te maken.</p>

<p><strong>Kan dit Privacy Beleid worden gewijzigd?</strong></p>

<p>Dit Privacy Beleid kan worden gewijzigd. Deze wijzigingen worden bekend gemaakt op de Website en/of via berichtgeving aan jou.</p>

<p><strong>Vragen?</strong></p>

<p>Mocht je nog vragen hebben over dit Privacy Beleid dan kun je een e-mail sturen aan <a href="mailto:raboom@knaek.nl" rel="nofollow" target="_blank">raboom@knaek.nl</a></p>

</div>
  `,
};
