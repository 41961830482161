import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Recovery from "../views/Recovery.vue";
import Registration from "../views/Registration.vue";
import Support from "../views/Support.vue";
import Account from "../views/Account.vue";
import Shop from "../views/Shop.vue";
import Store from "../views/Store.vue";
// import Shops from "../views/Shops.vue";
import Privacy from "../views/Privacy.vue";
import Terms from "../views/Terms.vue";
import Claims from "../views/Claims.vue";
import PasswordReset from "../views/PasswordReset.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/home/:name/:id",
        component: Shop,
        name: "HomeDetail",
      },
      {
        path: "/home/:urlPathSegment",
        component: Store,
        name: "HomeDetail",
      },
    ],
  },
  {
    path: "/inloggen",
    name: "Login",
    component: Login,
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
  },
  // {
  //   path: "/shops",
  //   name: "Shops",
  //   component: Shops,
  //   children: [
  //     {
  //       path: "/shops/:name/:id",
  //       component: Shop,
  //       name: "ShopDetail",
  //     },
  //   ],
  // },
  {
    path: "/registreren",
    name: "Registration",
    component: Registration,
    children: [
      {
        path: "/registreren/:name/:id",
        component: Shop,
        name: "RegistrationDetail",
      },
    ],
  },
  {
    path: "/registreren/new",
    name: "Registration-new",
    component: Registration,
    children: [
      {
        path: "/registreren/:name/:id",
        component: Shop,
        name: "RegistrationDetailNew",
      },
    ],
  },
  {
    path: "/registreren/nieuw",
    name: "Registration-Nieuw",
    component: Registration,
    children: [
      {
        path: "/registreren/:name/:id",
        component: Shop,
        name: "RegistrationDetailNieuw",
      },
    ],
  },
  {
    path: "/password-resets",
    name: "Recovery",
    component: Recovery,
  },
  {
    path: "/password-resets/:token",
    name: "PasswordReset",
    component: PasswordReset,
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
  },
  {
    path: "/privacy-beleid",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/algemene-voorwaarden",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/claims/new",
    name: "Claims",
    component: Claims,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.name !== "HomeDetail" &&
    from.name !== "HomeDetail" &&
    to.name !== "ShopDetail" &&
    from.name !== "ShopDetail" &&
    from.name !== "RegistrationDetail" &&
    to.name !== "RegistrationDetail" &&
    from.name !== "RegistrationDetailNew" &&
    to.name !== "RegistrationDetailNew"
  ) {
    window.scrollTo(0, 0);
  }

  return next();
});

export default router;
