<template>
  <div class="flex flex-col">
    <label>{{ label }}</label>
    <div>
      <div
        v-for="(option, i) in options"
        :class="{ active: option === picked }"
        class="pill mt-5"
        @click="setPicked"
        :key="i"
      >
        {{ option }}
      </div>
      <div
        class="pill dropdown mt-5"
        :class="{ active: dropdownLabel === picked }"
      >
        <span @click="expand = true">{{ dropdownLabel }}</span>
        <ul v-if="expand">
          <li @click="setDropdownPicked">Ik ben ouder/verzorger</li>
          <li @click="setDropdownPicked">Ik ben een leerkracht/docent</li>
          <li>
            <input
              @keyup="handleEnter"
              @input="setCustomPicked"
              v-model="inputValue"
              placeholder="Ik werk als..."
            />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DefaultRadioButton",
  props: ["options", "label"],
  data: () => {
    return {
      picked: "",
      inputValue: "",
      expand: false,
      dropdownLabel: "Anders, namelijk...",
      openInput: false
    };
  },
  methods: {
    setPicked({ target }) {
      const value = target.innerText;
      this.picked = value;
      this.expand = false;
      this.inputValue = "";
      this.dropdownLabel = "Anders, namelijk...";
      this.$emit("radioValue", value);
    },
    setCustomPicked({ target }) {
      const value = target.value;
      this.picked = value;
      this.dropdownLabel = value;
      this.$emit("radioValue", value);
    },
    setDropdownPicked({ target }) {
      const value = target.innerText;
      this.expand = false;
      this.picked = value;
      this.dropdownLabel = value;
      this.$emit("radioValue", value);
    },
    handleEnter({ keyCode }) {
      if (keyCode === 13) {
        this.expand = false;
      }
    }
  }
};
</script>

<style scoped>
.pill {
  display: inline-flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 28px;
  margin-right: 5px;
  cursor: pointer;
}

.active {
  border: 1px solid #1d9ef9;
  background-color: #f5f8f9;
}

.radio-input {
  border-radius: 8px;
  padding: 10px;
  background-color: white;
  width: 100%;
  margin-top: 16px;
}

.dropdown {
  display: block;
  width: 250px;
}

li {
  margin-top: 10px;
}

li:hover {
  color: #1d9ef9;
}

label {
  color: #0b0000;
  font-size: var(--m);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  width: 100%;
}
</style>
