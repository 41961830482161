export const TERMS = {
  html: `
  <div class="terms">
  <h1>Algemene voorwaarden</h1>
  <p>Deze gebruiksvoorwaarden zijn van toepassing op ieder gebruik dat je van RABOOM maakt, een en ander zoals hieronder gedefinieerd.</p>

<p>Wij adviseren je deze Gebruiksvoorwaarden zorgvuldig te lezen zodat je op de hoogte bent van jouw rechten en verplichtingen als je gebruik maakt van de diensten van RABOOM</p>

<p><strong>Artikel 1. Definities</strong></p>

<p>1.1 In deze Gebruiksvoorwaarden worden de volgende begrippen met een beginhoofdletter gebruikt, zowel in enkelvoud als in meervoud. Onder deze begrippen wordt het volgende verstaan:</p>

<p>Aankoop: een door Gebruiker via de website van een Partner gedane aankoop na welke Aankoop de Gebruiker Credits kan verdienen indien aan de daarvoor gestelde voorwaarden wordt voldaan;</p>

<p>Bezoeker: persoon die geen Profiel heeft aangemaakt, maar wel de Website bezoekt en daardoor toegang heeft tot Reviews;</p>

<p>Content: alle informatie, gegevens of bestanden die door Gebruikers via de Website beschikbaar worden gesteld;</p>

<p>Credits: virtuele waarde-eenheid binnen de Dienst, bestaande uit cashback. Je kan Credits verdienen door Aankopen te doen, welke Credits een directe nominale geldelijke waarde in euro&#39;s vertegenwoordigen;</p>

<p>Dienst: de dienst die RABOOM aan de Gebruiker verleent, zoals omschreven in art. 3;</p>

<p>Gebruiker: persoon die een Profiel heeft en gebruik maakt van de Diensten;</p>

<p>Gebruiksvoorwaarden: deze gebruiksvoorwaarden;</p>

<p>Inloggegevens: de gebruikersnaam en het wachtwoord van de Gebruiker waarmee hij toegang krijgt tot zijn Profiel en gebruik kan maken van de Dienst;</p>

<p>Intellectuele Eigendomsrechten: alle rechten van intellectuele eigendom en daarmee verwante rechten, zoals auteursrechten, merkrechten, databankrechten en naburige rechten, alsmede rechten op knowhow en eenlijnsprestaties;</p>

<p>Partij: een partij (de Gebruiker en/of RABOOM) bij deze Gebruiksvoorwaarden;</p>

<p>Partner: een bij RABOOM aangesloten webshop, alwaar een Gebruiker door het doen van een Aankoop Credits kan verzamelen;</p>

<p>Privacy Statement: het privacy statement van, beschikbaar via <a href="https://www.raboom.nl/privacy-beleid" rel="nofollow" target="_blank">https://www.raboom.nl/privacy-beleid</a>;</p>

<p>Profiel: een namens Gebruiker door RABOOM aangemaakte afgesloten persoonlijke pagina van Gebruiker, waar de Gebruiker zijn verdiende Credits, en overige informatie kan inzien;</p>

<p>Website: de website die door RABOOM ter beschikking wordt gesteld voor Gebruiker.</p>

<p><a href="http://www.raboom.nl" rel="nofollow" target="_blank">www.raboom.nl</a> (Raboom) is onderdeel van de besloten vennootschap Knaek B.V., kantoorhoudende te (1057GB) Amsterdam, aan de Admiralengracht 60H en ingeschreven in het register van de Kamer van Koophandel onder nummer 54953979; Bereikbaar via de contactpagina van RABOOM.</p>

<p><strong>Artikel 2. Algemeen</strong></p>

<p>2.1. RABOOM is te allen tijde gerechtigd deze Gebruiksvoorwaarden te wijzigen en/of aan te vullen. De meest actuele Gebruiksvoorwaarden zullen op de Website (<a href="https://www.raboom.nl/algemene-voorwaarden" rel="nofollow" target="_blank">https://www.raboom.nl/algemene-voorwaarden</a>) te vinden zijn of worden tijdens het gebruik van de Dienst onder de aandacht van de Gebruiker gebracht. Indien de Gebruiker niet instemt met de gewijzigde en/of aangevulde Gebruiksvoorwaarden, is zijn of haar enige mogelijkheid om de Dienst niet meer te gebruiken en zijn of haar Profiel te (laten) verwijderen.</p>

<p>2.2. Partijen wijzen uitdrukkelijk de toepasselijkheid van eventuele (algemene) voorwaarden van de Gebruiker of van derden van de hand.</p>

<p><strong>Artikel 3. De Dienst</strong></p>

<p>3.1. De Dienst biedt Gebruikers de mogelijkheid om een Profiel te plaatsen en daartoe toegang te verkrijgen, op welk Profiel de Gebruiker Credits kan verzamelen en kan inzien door middel van het doen van Aankopen bij Partners – na via de Website te zijn doorverwezen naar de website van deze Partner – en welke Credits de Gebruiker na een daartoe strekkend verzoek door RABOOM kan laten uitbetalen.</p>

<p>3.2. Op de Website kunnen nadere voorwaarden gesteld worden waaronder de Gebruiker gebruik kan maken van de Dienst. Deze nadere voorwaarden hebben onder meer betrekking op de wijze waarop Profielen kunnen worden aangemaakt, Credits kunnen worden verdiend en worden uitgekeerd.</p>

<p>3.3. RABOOM aanvaardt geen enkele aansprakelijkheid voor de juistheid, volledigheid en actualiteit van de informatie op de Website, alsmede de gegevens, aanbiedingen en overige informatie met betrekking tot Partners.</p>

<p><strong>Artikel 4. Toegang tot de Dienst en beschikbaarheid</strong></p>

<p>4.1. Om gebruik te kunnen maken van de gehele Dienst moet de Gebruiker een Profiel aanmaken op de manier zoals beschreven op de Website. Het is uitdrukkelijk niet toegestaan een Profiel op naam van een ander aan te maken. Tijdens de registratie moet de Gebruiker Inloggegevens opgeven, waarmee toegang kan worden verkregen tot zijn Profiel.</p>

<p>4.2. De Gebruiker is zelf verantwoordelijk voor het geheim houden van zijn Inloggegevens. Het is de Gebruiker niet toegestaan zijn Inloggegevens aan derden te verstrekken. De Gebruiker is aansprakelijk voor al het gebruik dat via de Inloggegevens van de Dienst wordt gemaakt. RABOOM mag ervan uitgaan dat de Gebruiker daadwerkelijk degene is die zich aanmeldt met zijn Inloggegevens. Zodra de Gebruiker weet of reden heeft te vermoeden dat Inloggegevens in handen zijn gekomen van onbevoegden, moet de Gebruiker RABOOM daarvan op de hoogte stellen, onverminderd zijn eigen verplichting om direct zelf doeltreffende maatregelen te treffen, zoals het veranderen van de het wachtwoord. RABOOM is niet aansprakelijk voor schade die voortvloeit uit enige ongeoorloofde toegang tot of gebruik van het Profiel en/of Dienst door derden.</p>

<p>4.3. RABOOM is te allen tijde gerechtigd om, zonder voorafgaande bekendmaking en zonder op enigerlei wijze schadeplichtig of aansprakelijk te worden jegens de Gebruiker, (i) procedurele en technische wijzigingen en/of verbeteringen op de Website en/of in de Dienst aan te brengen en (ii) de Dienst of het Profiel van de Gebruiker (tijdelijk of blijvend) buiten gebruik te stellen, te beperken of te beëindigen.</p>

<p>4.4. RABOOM garandeert niet dat (alle onderdelen van) de Website en de Dienst te allen tijde en zonder onderbrekingen of storingen toegankelijk zijn. RABOOM is op geen enkele wijze aansprakelijk of schadeplichtig jegens de Gebruiker voor enige schade die voortvloeit uit of het gevolg is van het (tijdelijk) onbeschikbaar zijn of (tussentijds) uitvallen van de Website en/of de Dienst.</p>

<p>4.5 .De Gebruiker en Bezoeker aanvaardt dat de Website en de Dienst alleen de functionaliteiten en overige eigenschappen bevatten zoals hij die aantreft op de Website en/of Dienst op het moment van gebruik (op “as is” basis). RABOOM sluit uitdrukkelijke en stilzwijgende garanties, toezeggingen en vrijwaringen van welke aard dan ook nadrukkelijk uit, waaronder begrepen maar niet beperkt tot garanties, toezeggingen en vrijwaringen ten aanzien van de kwaliteit, veiligheid, rechtmatigheid, integriteit en juistheid van de Website en de Dienst, tenzij anders is bepaald in deze Gebruiksvoorwaarden.</p>

<p>4.6. De Gebruiker is zelf verantwoordelijk voor de aanschaf en/of goede werking van de infrastructuur en deugdelijke telecomfaciliteiten (waaronder internetverbinding) die nodig zijn om gebruik te kunnen maken van de Dienst.</p>

<p>4.7. In aanvulling op de andere (rechts)middelen die RABOOM ten dienste staan, is RABOOM te allen tijde, indien zij daartoe gronden aanwezig acht, zonder opgave van redenen en zonder voorafgaande uitleg gerechtigd om de activiteiten van Gebruiker in verband met de Dienst (tijdelijk) te beperken, op te schorten of buiten gebruik te stellen, het Profiel tijdelijk of blijvend te verwijderen, bestanden, gegevens en/of materialen te verwijderen, een waarschuwing te doen uitgaan, de dienstverlening te beëindigen en te weigeren om diensten te verlenen, in het bijzonder - maar niet daartoe beperkt - indien:<br>
(i) Gebruiker handelt in strijd met deze Gebruiksvoorwaarden; <br>
(ii) RABOOM van mening is dat handelingen van Gebruiker schade of aansprakelijkheid aan hem zelf, andere Gebruikers, derden of RABOOM kunnen toebrengen. RABOOM zal hierdoor in geen geval aansprakelijk zijn.</p>

<p><strong>Artikel 5. Gebruik van de Dienst</strong></p>

<p>5.1. Gebruiker erkent en aanvaardt dat RABOOM slechts een Website biedt waarmee Gebruikers naar websites van Partners worden verwezen, waarna de Gebruiker een Aankoop kan doen. De Gebruiker is zelf volledig verantwoordelijk en aansprakelijk voor alle handelingen die hij met behulp van de Website en/of de Dienst verricht, derhalve is RABOOM nooit aansprakelijk voor:<br>
a) de inhoud van de website en overige informatie van haar Partners en/of derden;<br>
b) voor welke beslissing van een Gebruiker dan ook genomen op basis van Content of de inhoud van de website van een Partner;<br>
c) enig handelen van Partners en/of een derde.</p>

<p>5.2. RABOOM wordt nooit partij bij enige overeenkomst tussen Gebruiker en een Partner of een derde.</p>

<p>5.3. Het is de Gebruiker niet toegestaan om:<br>
a. Credits (trachten) te verzamelen door middel van frauduleus gedrag ten aanzien van hyperlinks, cookies, het omzeilen van beveiligingstechnieken en/of overige wijzen die niet zijn beschreven in deze Gebruiksvoorwaarden;<br>
b. de via de Dienst verkregen Content te verveelvoudigen, openbaar te maken, door te verkopen, te gebruiken voor commerciële doeleinden of anderszins ter beschikking te stellen aan derden;<br>
c. de Dienst te gebruiken voor andere doeleinden dan beschreven in deze Gebruiksvoorwaarden;<br>
d. derden in te schakelen om één van de in dit artikel vermelde handelingen te verrichten.</p>

<p>5.4 RABOOM behoudt te allen tijde het recht om Gebruiker toegang te ontzeggen tot <a href="http://www.raboom.nl" rel="nofollow" target="_blank">www.raboom.nl</a>. Bijvoorbeeld wanneer:<br>
a) Gebruiker als inactief wordt beschouwd en langer dan een jaar niet heeft ingelogd op <a href="http://www.raboom.nl" rel="nofollow" target="_blank">www.raboom.nl</a>.<br>
In dergelijke gevallen heeft RABOOM het recht om een alternatieve en vergelijkbare dienst aan te bieden aan Gebruiker zodat deze toegang blijft houden tot de in Artikel 6 opgespaarde Credits en kan Gebruiker daarnaast blijven genieten van vergelijkbare online Cashback voordelen bij webshops waar Gebruiker via <a href="http://www.raboom.nl" rel="nofollow" target="_blank">www.raboom.nl</a> dankzij RABOOM toegang tot had.</p>

<p><strong>Artikel 6. Credits</strong></p>

<p>6.1. Gebruiker heeft enkel en alleen recht op Credits indien deze aan de volgende voorwaarden voldoet:<br>
a. de Gebruiker is door middel van zijn Inloggegevens ingelogd op zijn Profiel;<br>
b. de Gebruiker is via een klikbare hyperlink op de Website doorverwezen naar de website van een Partner;<br>
c. de Gebruiker verricht de Aankoop bij een Partner in dezelfde browsersessie als de browsersessie waarin de Gebruiker verkeerde ten tijde van het uitvoeren van de handeling onder b.</p>

<p>6.2. Gebruiker begrijpt en erkent dat het toestaan van cookies van RABOOM en van Partners essentieel is om voor het verdienen van Credits in aanmerking te komen. RABOOM heeft geen zeggenschap over het accepteren van deze cookies door Gebruiker en Gebruiker is zelf volledig verantwoordelijk en aansprakelijk voor de correcte acceptatie van deze cookies om voor Credits in aanmerking te komen.</p>

<p>6.3. Gebruiker heeft enkel en alleen recht op het ontvangen van Credits indien deze een daartoe strekkende melding/e-mail van de Partner heeft ontvangen. Gebruiker zal de in de vorige volzin bedoelde melding op eerste verzoek aan RABOOM voorleggen.</p>

<p>6.4. De Partner waar de Aankoop is verricht beslist of Gebruiker daadwerkelijk recht heeft op Credits en RABOOM aanvaardt dan ook geen enkele aansprakelijkheid met betrekking tot het al dan niet uitkeren van (het juiste aantal) Credits. De Gebruiker vrijwaart RABOOM van iedere vordering die betrekking heeft op het niet toekennen van (voldoende) Credits.</p>

<p>6.5. Indien Gebruiker stelt recht te hebben op (een hoger aantal) Credits na het doen van een Aankoop bij een Partner, dient de Gebruiker zijn aankoopbevestiging aan RABOOM te overhandigen, welke aankoopbevestiging RABOOM vervolgens aan de Partner zal voorleggen. De Partner is voorts geheel verantwoordelijk om, naar eigen inzicht, een dergelijk verzoek tot uitkeren van (extra) Credits te beoordelen, en RABOOM aanvaardt geen enkele aansprakelijkheid ten aanzien van een in deze kwestie door de Partner genomen beslissing.</p>

<p>6.6. Indien aan alle vereisten met betrekking tot het vergaren van Credits is voldaan en de Partner goedkeuring heeft verleend met betrekking tot het uitkeren van Credits, spant RABOOM zich in om het aantal opeisbare Credits waar de Gebruiker recht op heeft op het Profiel van de Gebruiker weer te geven binnen maximaal 5 dagen nadat het (wettelijke) herroepingsrecht dat de Gebruiker ten aanzien van deze Aankoop bezit is verstreken, tenzij RABOOM op de Website meldt dat de uitkering van Credits langer duurt.</p>

<p>6.7. RABOOM is te allen tijde gerechtigd om het aantal werkelijk aan Gebruiker uit te keren Credits af te ronden, te verhogen of anderszins aan te passen, bijvoorbeeld indien de hoogte van de door een Partner uitgekeerde vergoeding wordt gewijzigd of indien RABOOM vermoedt dat de Credits door middel van frauduleus gedrag van Gebruiker zijn verkregen.</p>

<p>6.8. De Gebruiker verliest zijn recht op het verkrijgen van Credits indien deze zijn Aankoop retourneert of indien de overeenkomst die ten grondslag ligt aan de Aankoop waarmee de Credits zijn verdiend anderszins wordt beëindigd.</p>

<p>6.9. Voor het uitkeren van Credits is RABOOM afhankelijk van de vergoeding die een Partner terzake uitkeert. Indien een Partner, om welke reden dan ook, niet aan zijn uitkeringsverplichting voldoet, schort dat de verplichting van RABOOM om Credits uit te keren op. RABOOM zal zich in een dergelijk geval naar beste kunnen inspannen om de vergoeding van de Partner zo snel mogelijk te verkrijgen om de Credits zo spoedig mogelijk daarna uit te keren.</p>

<p>6.10. Alle kortingspercentages en aanbiedingen die op de Website staan vermeld zijn onder voorbehoud en kunnen op ieder moment door RABOOM worden aangepast of verwijderd. RABOOM kan niet garanderen dat een korting daadwerkelijk door een Partner wordt geaccepteerd en/of in combinatie met een andere aanbieding kan worden gebruikt.</p>

<p>6.11. Op iedere korting en/of aanbiedingen kunnen specifieke voorwaarden van toepassing zijn, welke voorwaarden per Partner dienen te worden geraadpleegd.</p>

<p><strong>Artikel 7. Uitkering</strong></p>

<p>7.1. De Gebruiker heeft slechts recht op uitkering van het nominale bedrag dat door het vergaarde aantal Credits wordt vertegenwoordigd op het moment dat:<br>
a. het door Gebruiker aantal vergaarde Credits een waarde van minimaal €1,- vertegenwoordigt;<br>
b. de Gebruiker via het Profiel een betalingsverzoek heeft ingediend;<br>
c. de Gebruiker geldige persoons-, adres- en rekeninggegevens heeft ingevoerd.</p>

<p>7.2. RABOOM streeft ernaar een in het vorige lid bedoeld betalingsverzoek binnen 28 dagen af te handelen. RABOOM kan echter niet garanderen dat deze termijn daadwerkelijk zal worden gehaald, noch aanvaardt zij enige aansprakelijkheid voor schade die is veroorzaakt door het overschrijden van deze termijn.</p>

<p>7.3. Indien het aantal Credits van Gebruiker een waarde van €150,- vertegenwoordigt, zal dit bedrag automatisch worden uitgekeerd. Dientengevolge is Gebruiker niet in staat meer Credits te verzamelen indien het totaal aantal door Gebruiker verzamelde Credits reeds €150,- bedraagt.</p>

<p>7.4. Credits worden geconverteerd naar Euro’s en worden overgemaakt naar een bankrekening die bij een Nederlandse bank geregistreerd is. Indien vergaarde Credits op basis van een vreemde valuta zijn gewaardeerd, wordt de nominale waarde van deze Credits in Euro’s bepaald door de actuele wisselkoers, zoals bepaald door RABOOM of een door RABOOM aan te wijzen derde.</p>

<p>7.5. Een betalingsverzoek kan slechts strekken ter uitkering van het volledige aantal door Gebruiker vergaarde Credits.</p>

<p>7.6. RABOOM aanvaardt geen enkele aansprakelijkheid voor schade ontstaan door het invoeren van foutieve of incomplete (betalings)gegevens door Gebruiker.</p>

<p>7.7. RABOOM is gerechtigd om tot ten hoogste 10% van het aan Gebruiker uit te keren bedrag in te houden om eventuele administratiekosten te dekken.</p>

<p><strong>Artikel 8. Intellectueel Eigendom</strong></p>

<p>8.1. De Intellectuele Eigendomsrechten met betrekking tot de Dienst, waaronder in ieder geval de Website, alsmede die via de Website toegankelijk gemaakte informatie zoals de teksten, look-and-feel, templates van de Profielen en overige webpagina’s, video-, audio- en beeld- of fotomateriaal, berusten bij RABOOM en/of haar licentiegevers.</p>

<p>8.2. Onder de voorwaarden die in deze Gebruiksvoorwaarden zijn gesteld, geeft RABOOM de Gebruiker een beperkt, persoonlijk, herroepelijk, niet-exclusief, niet sub-licentieerbaar en niet-overdraagbaar recht op toegang tot en gebruik van de Dienst, de Content en de Website, voor de doeleinden als beschreven in deze Gebruiksvoorwaarden.</p>

<p>8.3. De Gebruiker behoudt in beginsel de Intellectuele Eigendomsrechten met betrekking tot de geplaatste Content. De Gebruiker erkent en stemt ermee in dat hij door het beschikbaar stellen/uploaden van Content automatisch aan RABOOM een kosteloze, wereldwijde, onherroepelijke, sub-licentieerbare en overdraagbare licentie verstrekt om<br>
a. de Content te gebruiken, te verveelvoudigen, te verspreiden en openbaar te maken in verband met de Dienst; en<br>
b. de Content te gebruiken en verveelvoudigen (en aan derden toe te staan om te gebruiken en verveelvoudigen) in welke media dan ook voor marketing en/of promotie doeleinden in verband met de Dienst.</p>

<p>8.4.De Gebruiker doet uitdrukkelijk afstand van alle persoonlijkheidsrechten genoemd in artikel 25 van de Auteurswet, voor zover dit krachtens dat wetsartikel is toegestaan.</p>

<p>8.5.De Gebruiker staat er jegens RABOOM voor in dat hij volledig rechthebbende is ten aanzien van de Content en dat hij volledig gerechtigd is om de licentie als bedoeld in dit artikel te verlenen aan RABOOM.</p>

<p>8.6. Het is de Gebruiker uitdrukkelijk niet toegestaan om enige informatie die via de Dienst of de Website toegankelijk wordt gemaakt, waaronder mede begrepen Content van andere Gebruikers, te downloaden, te kopiëren, te wijzigen, te reverse engineren, openbaar te maken of voor enig ander doeleinde te gebruiken dan de in deze Gebruiksvoorwaarden genoemde doeleinden, tenzij RABOOM of de betreffende rechthebbende daar toestemming voor heeft gegeven dan wel dwingend Nederlands recht zulk gebruik toestaat.</p>

<p>8.7. Niets in deze Gebruiksvoorwaarden is bedoeld om enig Intellectueel Eigendomsrecht aan Gebruiker over te dragen. Gebruiker zal geen handelingen verrichten die inbreuk kunnen maken op de Intellectuele Eigendomsrechten van RABOOM zoals het registreren van domeinnamen, merken of Google AdWords die lijken op of identiek zijn aan enig object waarop RABOOM Intellectuele Eigendomsrechten heeft, of het opvragen of hergebruiken van substantiële delen of het herhaald en systematisch opvragen of hergebruiken van niet-substantiële delen van de Website.</p>

<p><strong>Artikel 9. Privacy</strong></p>

<p>9.1. RABOOM verwerkt de contactgegevens (e-mailadres en geboortedatum) die nodig zijn voor het beheer van de onze gebruikers. Daarnaast worden gebruiksgegevens (IP-adres, bezochte pagina’s, bezoekduur, locatie (stad, land, taal), browser (Internet Explorer of Google Chrome) en klikgedrag gebruikt om jouw cashback te registreren. Daarnaast voor statische doeleinden ten behoeve van de verbetering van ons cashback platform. De persoonsgegevens van de gebruiker zijn opgenomen in ons bestand en zullen vertrouwelijk en conform de Algemene Verordening Gegevensbescherming behandeld worden. Meer informatie over het privacy-beleid van RABOOM kan gevonden worden op de <a href="https://www.raboom.nl/privacy-beleid" rel="nofollow" target="_blank">privacy pagina</a>.</p>

<p>9.2. De transactiedetails slaan we op om jouw cashbacks uit te kunnen keren, je opgespaarde tegoed te kunnen tonen en je te helpen bij het claimen van mislukte transacties. Deze data bewaren wij voor onze boekhouding zeven jaar. De transactiedetails worden geanonimiseerd wanneer je je account bij ons laat verwijderen.<br>
Voor het beheren van jouw account verwerken we ook transactiedetails zoals het aankoopbedrag excl. BTW, transactiedatum en –tijd en de webshop waar je je aankoop hebt gedaan.</p>

<p>9.3. Het lid heeft onder meer het recht op inzage van zijn persoonsgegevens, mits zijn identiteit kan worden vastgesteld, evenals het recht op verwijdering van zijn persoonsgegevens, mits deze niet noodzakelijk zijn voor het uitvoeren van de Overeenkomst. Tevens beschikt het lid over het recht om verbeteringen in zijn gegevens aan te laten brengen indien deze foutief zijn. </p>

<p>9.4. Persoonsgegevens van het lid worden gedeeld met Rabobank ten behoeve van de uitbetaling van de cashback van onze gebruikers.</p>

<p>9.5. Telefoongesprekken met een lid kunnen door RABOOM worden opgenomen voor trainings- en beoordelingsdoeleinden.</p>

<p><strong>Artikel 10. Garanties en vrijwaringen</strong></p>

<p>10.1. De Gebruiker is jegens RABOOM aansprakelijk voor, en vrijwaart RABOOM volledig tegen, alle schade en kosten die RABOOM lijdt of maakt ten gevolge van (i) een toerekenbare tekortkoming in de nakoming van een overeenkomst met RABOOM door de Gebruiker, (ii) enig handelen van de Gebruiker bij het gebruik van de Dienst of (iii) van een onrechtmatige daad. Alle door RABOOM gemaakte kosten en geleden schade die op enige wijze verband houdt met dergelijke aanspraken zullen door de Gebruiker worden vergoed.</p>

<p>10.2. De Gebruiker staat er jegens RABOOM voor in dat de informatie die hij bij het aanmaken van zijn Profiel en het plaatsen van Content verstrekt compleet, actueel en juist is. De Gebruiker erkent en begrijpt dat de correctheid, actualiteit en volledigheid van de verstrekte Content van groot belang is voor de optimale werking van de Dienst.</p>

<p>10.3. De Gebruiker is zelf volledig verantwoordelijk voor het voldoen aan de (fiscale) verplichtingen die voortvloeien uit het vergaren en laten uitbetalen van Credits en vrijwaart RABOOM voor alle vorderingen van derden – waar onder begrepen de Belastingdienst – ter zake.</p>

<p>10.4. De Gebruiker staat er jegens RABOOM voor in dat hij gerechtigd is om van de Dienst gebruik te maken en dat hij zal handelen in overeenstemming met deze Gebruiksvoorwaarden.</p>

<p><strong>Artikel 11. Aansprakelijkheid</strong></p>

<p>11.1. RABOOM aanvaardt geen enkele aansprakelijkheid voor schade ten gevolge van het verlenen van de Dienst dan wel uit onrechtmatige daad of anderszins, voor zover dit op grond van dwingend recht is toegestaan.</p>

<p>11.2. Indien RABOOM aansprakelijk is jegens Gebruiker voor schade uit welke hoofde dan ook, is RABOOM uitsluitend aansprakelijk voor directe schade die Gebruiker lijdt als gevolg van een aan RABOOM toerekenbare tekortkoming en/of onrechtmatige daad. De totale aansprakelijkheid onder de overeenkomst zal nooit meer dan €250,- bedragen.</p>

<p>11.3. Onder directe schade wordt uitsluitend verstaan:<br>
a. materiële schade aan zaken;<br>
b. redelijke kosten gemaakt ter voorkoming of beperking van directe schade, die als gevolg van de gebeurtenis waarop de aansprakelijkheid berust mochten worden verwacht;<br>
c. redelijke kosten gemaakt ter vaststelling van de schadeoorzaak.</p>

<p>11.4. Iedere aansprakelijkheid van RABOOM voor andere dan directe schade, zoals beschreven in art. 11.3, waaronder in ieder geval begrepen gevolgschade, is uitgesloten. Onder gevolgschade wordt onder meer begrepen, winstderving, gemiste besparingen, verminderde goodwill, reputatieschade, schade door bedrijfsstagnatie, verliezen, kosten gemaakt ter voorkoming of vaststelling van gevolgschade, vermissing, verwisseling of beschadiging van elektronische gegevens en/of schade door vertraging in de transport van het dataverkeer.</p>

<p>11.5. De in dit artikel opgenomen beperking van aansprakelijkheid is niet van toepassing in geval van opzet of bewuste roekeloosheid van RABOOM zelf of haar leidinggevenden.</p>

<p>11.6. De aansprakelijkheid van RABOOM wegens toerekenbare tekortkoming in de nakoming van een overeenkomst ontstaat in alle gevallen slechts indien Gebruiker RABOOM onverwijld en deugdelijk schriftelijk in gebreke stelt, waarbij een redelijke termijn van tenminste twee weken ter zuivering van de toerekenbare tekortkoming wordt gesteld, en RABOOM ook na die termijn toerekenbaar te kort blijft schieten in de nakoming van haar verplichtingen, behoudens in geval van een blijvende toerekenbare tekortkoming. De ingebrekestelling dient een zo volledig en gedetailleerd mogelijke omschrijving van de tekortkoming te bevatten, zodat RABOOM in staat is adequaat te reageren.</p>

<p>11.7. Voorwaarde voor het ontstaan van enig recht op schadevergoeding is steeds dat de Gebruiker de schade zo spoedig mogelijk na het ontstaan daarvan schriftelijk bij RABOOM meldt. Iedere vordering tot schadevergoeding tegen RABOOM vervalt door het enkele verloop van 12 maanden na het ontstaan van de vordering.</p>

<p><strong>Artikel 12. Overmacht</strong></p>

<p>12.1. Er is geen sprake van een toerekenbare tekortkoming in de nakoming van de overeenkomst door RABOOM indien sprake is van overmacht.</p>

<p>12.2. Onder overmacht wordt onder meer begrepen ziekte van werknemers en/of afwezigheid van voor het aanbieden van RABOOM cruciale medewerkers, onderbrekingen van de toelevering van elektriciteit, stakingen, oproer, overheidsmaatregelen, brand, natuurrampen, overstromingen, tekortkomingen van toeleveranciers van RABOOM, tekortkomingen van door RABOOM ingeschakelde derden, storingen in de verbinding met internet, de niet-beschikbaarheid van diensten van derden, hardware-storingen, storingen in (telecommunicatie)netwerken en andere onvoorziene omstandigheden.</p>

<p><strong>Artikel 13. Duur en beëindiging</strong></p>

<p>13.1. De Gebruiker heeft het recht om op elk gewenst moment het gebruik van de Dienst te staken en het Profiel te verwijderen.</p>

<p>13.2. Als je niet je Profiel bezoekt, waarbij je ‘inlogt’ met je persoonlijke gebruikersnaam en wachtwoord, voor een aaneengesloten periode van 12 maanden, dan zal na de 365e dag (de ‘Afwezige Periode’) je Profiel als ‘Inactief’ worden geregistreerd. Zodra je Profiel als ‘Inactief’ is geregistreerd, houdt RABOOM zich het recht voor administratiekosten (de ‘Inactieve Profiel Kosten’) van 5 EUR maandelijks in rekening te brengen (of omgerekend naar de op dat moment geldende koers van EUR naar GBP, USD, of ZAR). RABOOM trekt dit bedrag maandelijks van je Saldo af zolang je als ‘Inactief’ geregistreerd bent en er nog een positief Saldo op je Profiel staat. Het is mogelijk je profiel te her-activeren door in te loggen op je Profiel. De maandelijkse afschrijving van de ‘Inactieve Profiel Kosten’ stopt vervolgens automatisch.</p>

<p>13.3. Voor de beëindiging (aanvraag verwijderen gegevens) van het Profiel van Gebruiker, om welke reden dan ook, zal de Gebruiker de Credits zelf moeten laten uitbetalen. Indien Gebruiker één (1) maand na de aanvraag van het verwijderen van de gegevens de Credits niet heeft uitgekeerd is RABOOM gerechtigd om de Credits af te schrijven.</p>

<p>13.4. Bij beëindiging van de overeenkomst, om welke reden dan ook, vervalt per direct het recht van de Gebruiker om de Dienst te gebruiken en wordt de toegang tot de Dienst direct ontzegd. RABOOM verwijdert bij beëindiging, om welke reden dan ook, onmiddellijk het Profiel. RABOOM blijft gerechtigd om alle Content die de Gebruiker op de Website heeft geplaatst te gebruiken zoals bepaald in artikel 8.3. Na het einde van de overeenkomst zal RABOOM echter op het eerste verzoek van de Gebruiker alle Content die de Gebruiker op de Website heeft geplaatst verwijderen of anonimiseren. RABOOM is niet gehouden om na beëindiging van de overeenkomst, enige Content aan de Gebruiker te verstrekken en/of te converteren.</p>

<p><strong>Artikel 14. Varia</strong></p>

<p>14.1. Op deze Gebruiksvoorwaarden en al het gebruik van de Dienst en de Website is Nederlands recht van toepassing. De toepassing van het Weens Koopverdrag (CISG) wordt nadrukkelijk uitgesloten.</p>

<p>14.2. Alle geschillen die tussen de Gebruiker en RABOOM ontstaan, zullen worden voorgelegd aan de bevoegde rechter in het arrondissement van Amsterdam tenzij dwingend recht bepaalt dat het geschil aan een andere rechter moet worden voorgelegd.</p>

<p>14.3. RABOOM mag rechten en verplichtingen die uit deze Gebruiksvoorwaarden voortvloeien overdragen aan derden en zal de Gebruiker daarvan op de hoogte stellen. Indien de Gebruiker deze overdracht van verplichtingen aan een derde niet acceptabel vindt, kan de Gebruiker het gebruik van de Dienst staken en zijn Profiel (laten) beëindigen.</p>

<p>14.4. Is of worden deze Gebruiksvoorwaarden gedeeltelijk ongeldig, dan blijven de Gebruiker en RABOOM aan het overblijvende gedeelte verbonden. RABOOM zal het ongeldige gedeelte vervangen door bedingen die wel geldig zijn en waarvan de rechtsgevolgen, gelet op de inhoud en de strekking van deze Gebruiksvoorwaarden, zoveel mogelijk overeenstemmen met die van het ongeldige gedeelte.</p>

<p>Deze voorwaarden zijn voor het laatst aangepast op 17 augustus 2023.</p>
</div>
  `,
};
