<template>
  <fragment>
    <header-component v-if="!loggedIn" :hadRedirect="hadRedirect" />
    <dynamic-header v-if="loggedIn" :loggedIn="loggedIn" :user="user">
      <template v-slot:title>
        Welkom
        <template v-if="user">
          {{ user.first_name ? user.first_name : getFirstPartOfEmail(user) }}
        </template>
      </template>
    </dynamic-header>
    <div class="container mx-auto">
      <carousel v-if="spotlightStores.length" title="Populair deze week" :settings="settingsSmallSlider">
        <carousel-item-small :store="store" v-for="store in spotlightStores" :key="store.storeId" :showPromotion="true" />
      </carousel>
      <store-list v-if="loggedIn && stores.length" :stores="stores" />
      <!-- <shops-list v-if="shops.length" :shops="shops" /> -->
      <video-section v-if="!loggedIn" />
    </div>
    <div class="container mx-auto">
      <carousel v-if="!loggedIn && shops.length" title="Exclusieve shops" :settings="settingsBigSlider">
        <!-- <carousel v-if="shops.length" title="Exclusieve shops" :settings="settingsBigSlider"> -->
        <carousel-item-big :shop="shop" v-for="(shop, i) in shops.filter(s => s.isExclusive)" :key="i" />
      </carousel>
      <explination-section />
    </div>
    <cta-section />
    <contact-section />
    <footer-component />
    <router-view />
  </fragment>
</template>

<script>
import HeaderComponent from "@/components/patterns/HeaderComponent";
import Carousel from "@/components/patterns/Carousel.vue";
import VideoSection from "@/components/patterns/VideoSection.vue";
import CtaSection from "@/components/patterns/CtaSection.vue";
import ContactSection from "@/components/patterns/ContactSection.vue";
import FooterComponent from "@/components/patterns/FooterComponent.vue";
import ExplinationSection from "@/components/patterns/ExplinationSection.vue";
import CarouselItemSmall from "@/components/patterns/CarouselItemSmall.vue";
import CarouselItemBig from "@/components/patterns/CarouselItemBig.vue";
import StoreList from "../components/patterns/StoreList.vue";
import DynamicHeader from "../components/patterns/DynamicHeader.vue";
import { BASE_SLIDER } from "../constants/constants";
import { mapGetters, mapState } from "vuex";
import { getFirstPartOfEmail } from "../utils/utils";

export default {
  components: {
    HeaderComponent,
    Carousel,
    VideoSection,
    CtaSection,
    ContactSection,
    FooterComponent,
    ExplinationSection,
    CarouselItemSmall,
    CarouselItemBig,
    DynamicHeader,
    StoreList,
  },

  metaInfo: {
    titleTemplate: "%s | Jong, slim, en exclusief!",
    description:
      "RABOOM is jong, slim en exclusief, registreer je bij RABOOM en ontvang cashback bij al je favoriete webshops!",
  },

  methods: {
    getFirstPartOfEmail,
    setCurrentWindowSize() {
      this.currentWidth = window.innerWidth;
    },

    setSlideNumber(screenWidth, { small, medium, big }) {
      if (screenWidth < 800) {
        return small;
      } else if (screenWidth < 1200) {
        return medium;
      } else {
        return big;
      }
    },

    setSpotlightStores() {
      // Ensure we don't mutate the state, so we make a copy of the stores
      const newStores = JSON.parse(JSON.stringify(this.stores));
      // Filter out stores that don't have a promotion
      const storesWithPromotion = newStores.filter((store) => {
        if (!store.deals || store.deals.length === 0) return;
        return store.deals.some((deal) => deal.type === "promotion");
      });
      // Shuffle the stores
      const shuffled = storesWithPromotion.sort(() => 0.5 - Math.random());
      // Get sub-array of first n elements after shuffled
      this.spotlightStores = shuffled.slice(0, 8);
    }
  },

  async beforeMount() {
    await this.$store.dispatch("cashback/getStores");

    this.setSpotlightStores();

    if (this.loggedIn) {
      this.$store.dispatch("cashback/getUser");
    }
  },

  mounted() {
    if (this.$router.history.current.redirectedFrom === "/warm-welkom") {
      this.hadRedirect = true;
    }

    this.currentWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.setCurrentWindowSize();
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.setCurrentWindowSize());
  },

  computed: {
    ...mapState("cashback", {
      stores: (state) => state.stores,
      shops: (state) => state.shops,
      user: (state) => state.user,
    }),
    ...mapGetters("cashback", ["loggedIn"]),

    settingsBigSlider() {
      const { setSlideNumber, currentWidth } = this;
      return {
        ...BASE_SLIDER,
        slidesToShow: setSlideNumber(currentWidth, {
          small: 1,
          medium: 2,
          big: 2,
        }),
        slidesToScroll: setSlideNumber(currentWidth, {
          small: 1,
          medium: 2,
          big: 2,
        }),
      };
    },

    settingsSmallSlider() {
      const { setSlideNumber, currentWidth } = this;
      return {
        ...BASE_SLIDER,
        autoplay: true,
        slidesToShow: setSlideNumber(currentWidth, {
          small: 2,
          medium: 2,
          big: 4,
        }),
        slidesToScroll: setSlideNumber(currentWidth, {
          small: 1,
          medium: 2,
          big: 2,
        }),
      };
    },
  },

  data: () => {
    return {
      currentWidth: "",
      hadRedirect: false,
      spotlightStores: [],
    };
  }
};
</script>
