import { BASE_HEADERS, TOKEN } from "../../constants/constants";
import { tokenIsValid } from "../../utils/utils";

const CASHBACK_API = process.env.VUE_APP_CASHBACK_API_URL;

const cashback = {
  namespaced: true,
  state: {
    shops: [],
    stores: [],
    user: null,
    token: null,
    transactions: [],
    payouts: [],
    trackingLink: null,
    payout: null,
    categories: []
  },

  getters: {
    loggedIn: (state) => state.token !== null,
    stores: (state) => state.stores
  },

  mutations: {
    setShops: (state, payload) => (state.shops = payload),
    setStores: (state, payload) => (state.stores = payload),
    setUser: (state, payload) => (state.user = payload),
    setToken: (state, payload) => (state.token = payload),
    setTransactions: (state, payload) => (state.transactions = payload),
    setTrackingLink: (state, payload) => (state.trackingLink = payload),
    setPayouts: (state, payload) => (state.payouts = payload.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))),
    setPayout: (state, payload) => (state.payout = payload),
    setCategories: (state, payload) => (state.categories = payload)
  },

  actions: {
    getStores: async ({ commit }) => {
      const shops = await (await fetch(CASHBACK_API + "/stores")).json();
      commit("setStores", shops);
    },

    getTransactions: async ({ commit, state }) => {
      const transactions = await (
        await fetch(CASHBACK_API + "/transactions", {
          headers: {
            ...BASE_HEADERS,
            Authorization: `Bearer ${state.token}`,
          },
        })
      ).json();

      commit("setTransactions", transactions);
    },

    getPayouts: async ({ commit, state }) => {
      const payouts = await (
        await fetch(CASHBACK_API + "/payouts", {
          headers: {
            ...BASE_HEADERS,
            Authorization: `Bearer ${state.token}`,
          },
        })
      ).json();

      commit("setPayouts", payouts);
    },

    getTokens: async ({ commit }, credentials) => {
      const response = await fetch(CASHBACK_API + "/auth/token", {
        method: "POST",
        headers: BASE_HEADERS,
        body: JSON.stringify(credentials),
      });

      if (!response.ok) {
        // should check the type of error in the future
        throw Error(response);
      }

      const tokens = await response.json();

      // Save key in local storage
      localStorage.setItem(TOKEN, tokens.access_token);

      commit("setToken", tokens.access_token);
    },

    getUser: async ({ commit, state }) => {
      const user = await (
        await fetch(CASHBACK_API + "/user", {
          headers: {
            ...BASE_HEADERS,
            Authorization: `Bearer ${state.token}`,
          },
        })
      ).json();

      commit("setUser", user);

      return user;
    },

    updateUser: ({ state }, { email, password, first_name }) => {
      fetch(CASHBACK_API + "/user", {
        method: "PUT",
        headers: {
          ...BASE_HEADERS,
          Authorization: `Bearer ${state.token}`,
        },
        body: JSON.stringify({ email, password, first_name }),
      });
    },

    sendPasswordReset: async (_, email) => {
      await fetch(CASHBACK_API + `/password-resets`, {
        method: "POST",
        headers: BASE_HEADERS,
        body: JSON.stringify({ email }),
      });
    },

    updatePassword: async (_, { password, token }) => {
      return await fetch(CASHBACK_API + `/password-resets/${token}`, {
        method: "POST",
        headers: BASE_HEADERS,
        body: JSON.stringify({ password }),
      });
    },

    signUp: async ({ commit }, signUpCredential) => {
      const response = await fetch(CASHBACK_API + "/signup", {
        method: "POST",
        headers: BASE_HEADERS,
        body: JSON.stringify(signUpCredential),
      });

      if (!response.ok) {
        // should check the type of error in the future
        throw Error(response);
      }

      const tokens = await response.json();

      // Save key in local storage
      localStorage.setItem(TOKEN, tokens.access_token);

      commit("setToken", tokens.access_token);
    },

    checkLocalStorage: async ({ commit }) => {
      const tokensInStorage = localStorage.getItem(TOKEN);

      if (tokenIsValid()) {
        commit("setToken", tokensInStorage);
      } else {
        localStorage.removeItem(TOKEN);
      }
    },

    removeToken: ({ commit }) => {
      localStorage.removeItem(TOKEN);
      commit("setToken", null);
    },

    getTrackingLink: async ({ state }, dealId) => {
      const response = await fetch(
        CASHBACK_API + `/deals/${dealId}/initiate`,
        {
          method: "POST",
          headers: {
            ...BASE_HEADERS,
            Authorization: `Bearer ${state.token}`,
          },
        }
      );

      const link = await response.json();

      return link.redirectUrl
    },

    trackShopView: async ({ state }, shopId) => {
      fetch(
        CASHBACK_API + `/programs/${shopId}/view`,
        {
          method: "POST",
          headers: {
            ...BASE_HEADERS,
            Authorization: `Bearer ${state.token}`,
          }
        }
      );
    },

    payout: async ({ state, commit }, data) => {
      const response = await fetch(CASHBACK_API + `/payouts`, {
        method: "POST",
        headers: {
          ...BASE_HEADERS,
          Authorization: `Bearer ${state.token}`,
        },
        body: JSON.stringify({ bankAccountNumber: data.accountNumber, accountName: data.accountName}),
      });

      const payout = await response.json();

      commit("setPayout", payout.amount);
    },

    validateEmail: async (_, email) => {
      const response = await fetch(CASHBACK_API + `/verify_email`, {
        method: "POST",
        headers: BASE_HEADERS,
        body: JSON.stringify({ email }),
      });

      const emailIsValid = await response.json();

      return emailIsValid.valid;
    },

    validateEmailPassword: async (_, email) => {
      const response = await fetch(CASHBACK_API + `/verify_empty_password`, {
        method: "POST",
        headers: BASE_HEADERS,
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      return data.empty_password;
    },

    validateVerification: async (_, verification) => {
      const response = await fetch(CASHBACK_API + `/verify_verification`, {
        method: "POST",
        headers: BASE_HEADERS,
        body: JSON.stringify({ verification }),
      });

      const verificationIsValid = await response.json();

      return verificationIsValid.valid;
    },

    getMagicLink: async ({ state }) => {
      const response = await fetch(CASHBACK_API + `/user/magic_links`, {
        method: "POST",
        headers: {
          ...BASE_HEADERS,
          Authorization: `Bearer ${state.token}`,
        },
      });

      const parsedResponse = await response.json();

      return parsedResponse.link;
    },

    getCategories: async ({ commit }) => {
      const response = await fetch(CASHBACK_API + `/categories`, {
        method: "GET",
        headers: {
          ...BASE_HEADERS,
        },
      });

      const parsedResponse = await response.json();

      commit("setCategories", parsedResponse)
    },

    sendMessage: async (_, message) => {
      const response = await fetch(`${CASHBACK_API}/support-requests`, {
        method: "POST",
        headers: {
          ...BASE_HEADERS,
        },
        body: JSON.stringify(message)
      })

      return response
    },

    createTicket: async ({ state }, formData) => {
      const response = await fetch(`${CASHBACK_API}/tickets`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${state.token}`
        },
        body: formData
      })

      return response
    },

  }
};

export default cashback;
