<template>
  <div class="message-section ">
    <div class="content-wrapper">
      <div class="update-wrapper">
        <default-text-input type="white" class="mt-10 mr-10" :placeholder="user.first_name" label="Voornaam" :value="updateCredentials.first_name"
          :validation="isNotEmpty" @validate="nameIsValid = $event"
          @inputValue="updateCredentials.first_name = $event" />
        <default-button :disabled="!nameIsValid" @click="updateUser({ first_name: updateCredentials.first_name })"
          text="Aanpassen" type="gradient" />
      </div>
      <div class="update-wrapper">
        <default-text-input type="white" class="mt-10 mr-10" :placeholder="user.email" label="Email" :validation="validateEmail"
          :value="updateCredentials.email" @validate="emailIsValid = $event"
          @inputValue="updateCredentials.email = $event" />
        <default-button :disabled="!emailIsValid" @click="updateUser({ email: updateCredentials.email })"
          text="Aanpassen" type="gradient" />
      </div>
      <div class="update-wrapper">
        <default-text-input type="white" class="mt-10 mr-10" label="Nieuw wachtwoord"
          :value="updateCredentials.password" :validation="isNotEmpty" @validate="passwordIsValid = $event"
          @inputValue="updateCredentials.password = $event" />
        <default-button :disabled="!passwordIsValid" @click="updateUser({ password: updateCredentials.password })"
          text="Aanpassen" type="gradient" />
      </div>
      <modal v-if="showModal" :close="closeModal">
        <h1>Je gegevens zijn aangepast!</h1>
        <p>
          {{ modalNotification }}
        </p>
      </modal>
      <!-- <div class="text-center">
        <default-button
          class="mt-10"
          text="Je account verwijderen"
          type="gray"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import DefaultButton from "../elements/DefaultButton.vue";
import DefaultTextInput from "../elements/DefaultTextInput.vue";
import { validateEmail, isNotEmpty } from "../../utils/utils";
import Modal from "../patterns/Modal.vue";

export default {
  name: "UpdateProfile",
  components: { DefaultButton, DefaultTextInput, Modal },
  props: ["user"],
  data: () => {
    return {
      emailIsValid: false,
      passwordIsValid: false,
      nameIsValid: false,
      updateCredentials: {
        email: "",
        password: "",
        first_name: "",
      },
      showModal: false,
      modalNotification: "",
    };
  },
  methods: {
    validateEmail,
    isNotEmpty,
    updateUser({ password, email, first_name }) {
      this.$store.dispatch("cashback/updateUser", {
        password,
        email,
        first_name,
      });

      this.modalNotification = `Je "${password
          ? "password"
          : email
            ? "Email"
            : first_name
              ? "Voornaam"
              : "onbekend"
        }" is gewijzigd.`;
      this.showModal = true;
    },
    closeModal() {
      this.updateCredentials = {
        email: "",
        password: "",
        first_name: "",
      };
      this.emailIsValid = false;
      this.passwordIsValid = false;
      this.nameIsValid = false;
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
.message-section {
  padding: 140px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f5f8f9;
  widows: 100%;
  height: 100%;
}

.content-wrapper {
  max-width: 520px;
}

.update-wrapper {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@media only screen and (max-width: 600px) {
  .update-wrapper {
    display: block;
  }

  .message-section {
    padding: 0 15px 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #f5f8f9;
    widows: 100%;
    height: 100%;
  }

  .update-wrapper button {
    margin-top: 20px;
    margin-left: calc(100% - 140px);
  }
}
</style>
