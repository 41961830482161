<template>
  <div class="wrapper">
    <h1 class="text-4xl font-bold text-black">
      Vragen? We leggen het graag persoonlijk aan je uit.
    </h1>
    <default-button
      class="mt-10"
      @click="$router.push({ name: 'Support' })"
      text="Neem contact op"
      type="gray"
    />
  </div>
</template>

<script>
import DefaultButton from "../elements/DefaultButton.vue";
export default {
  name: "ContactSection",
  components: {
    DefaultButton
  },
  props: ["cmsData"]
};
</script>

<style scoped>
h2 {
  font-size: var(--xl6);
  line-height: var(--xl7);
  color: var(--black);
}

.wrapper {
  padding: 120px 0;
  text-align: center;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    padding: 40px 15px;
    text-align: center;
    max-width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  h1 {
    font-size: var(--xl2);
    line-height: var(--xl3);
    color: var(--black);
  }
}
</style>
