<template>
  <fragment>
    <hero-image>
      <h1 class="title">
        Registreer je <b>gratis</b> en ontvang korting bij jouw favoriete
        webshops!
      </h1>
      <register-tab />
    </hero-image>
    <div class="container mx-auto">
      <carousel v-if="shops.length" title="Korting bij onder andere" :settings="settingsSmallSlider">
        <carousel-item-small :shop="shop" v-for="(shop, i) in shops" :key="i" />
      </carousel>
    </div>
    <router-view />
    <footer-component :sticky="true" />
  </fragment>
</template>

<script>
import HeroImage from "../components/patterns/HeroImage.vue";
import RegisterTab from "../components/patterns/RegisterTab.vue";
import FooterComponent from "../components/patterns/FooterComponent.vue";
import Carousel from "../components/patterns/Carousel.vue";
import CarouselItemSmall from "../components/patterns/CarouselItemSmall.vue";
import { mapState } from "vuex";
import { BASE_SLIDER } from "../constants/constants";

export default {
  name: "Registartion",
  components: {
    HeroImage,
    RegisterTab,
    FooterComponent,
    Carousel,
    CarouselItemSmall,
  },
  metaInfo: {
    titleTemplate: "%s | Registratie pagina",
  },
  beforeMount() {
    this.$store.dispatch("cashback/getStores");
  },
  mounted() {
    this.currentWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.setCurrentWindowSize();
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.setCurrentWindowSize());
  },
  methods: {
    setCurrentWindowSize() {
      this.currentWidth = window.innerWidth;
    },

    setSlideNumber(screenWidth, { small, medium, big }) {
      if (screenWidth < 800) {
        return small;
      } else if (screenWidth < 1200) {
        return medium;
      } else {
        return big;
      }
    },
  },
  computed: {
    ...mapState("cashback", {
      shops: (state) => state.shops,
    }),
    settingsSmallSlider() {
      const { setSlideNumber, currentWidth } = this;
      return {
        ...BASE_SLIDER,
        autoplay: true,
        slidesToShow: setSlideNumber(currentWidth, {
          small: 2,
          medium: 2,
          big: 4,
        }),
        slidesToScroll: setSlideNumber(currentWidth, {
          small: 1,
          medium: 2,
          big: 2,
        }),
      };
    },
  },
};
</script>

<style scoped>
.title {
  margin: 80px 0;
  color: var(--black);
  font-family: "GT Walsheim Pro";
  font-size: var(--xl5);
  letter-spacing: 0;
  line-height: var(--xl6);
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: var(--xl4);
    line-height: var(--xl5);
    margin: 60px 0;
  }
}
</style>
