<template>
  <header class="m-0 p-0">
    <div class="container mx-auto content-wrapper">
      <div class="flex items-center justify-between py-6">
        <router-link :to="{ name: 'Home' }">
          <img class="logo" src="@/assets/img/icons/logo.svg" alt="Logo" />
        </router-link>
        <nav>
          <ul class="flex items-center">
            <li class="mx-2 text-white hide-mobile">
              <router-link :to="{ name: 'Support' }">Support</router-link>
            </li>
            <li class="mx-2">
              <default-button class="mx-2" text="Inloggen" @click="$router.push({ name: 'Login' })" type="white" />
            </li>
            <li class="mx-2 hide-mobile">
              <default-button class="mx-2" text="Registreren" @click="$router.push(registerPushData)" type="gradient" />
            </li>
          </ul>
        </nav>
      </div>
      <div class="question-mark-wrapper hide-desktop">
        <router-link :to="{ name: 'Support' }">
          <img class="question" :src="require(`@/assets/img/question-mark.png`)" alt="raboom-icon" />
        </router-link>
      </div>
      <div class="header-content">
        <div class="header-text">
          <h1>Jong, slim en exclusief!</h1>
          <h2>Met één extra klik tot 75% korting bij je favoriete webshops</h2>
          <default-button class="register-button" text="Registreren" @click="$router.push(registerPushData)"
            type="gradient" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import DefaultButton from "../elements/DefaultButton.vue";

export default {
  name: "HeaderComponent",
  components: {
    DefaultButton,
  },
  props: ["hadRedirect"],
  computed: {
    registerPushData() {
      return this.hadRedirect
        ? { name: "Registration", query: { welcomeCode: "RABOOM785" } }
        : { name: "Registration" };
    },
  },
};
</script>

<style scoped>
header {
  height: 700px;
  background: url("../../assets/img/hero.png") no-repeat center center;
  background-size: cover;
}

h1 {
  color: var(--white);
  font-size: var(--xl4);
  letter-spacing: 0;
  line-height: var(--xl5);
}

h2 {
  color: var(--white);
  font-size: var(--xl2);
  letter-spacing: 0;
  line-height: var(--xl3);
}

.register-button {
  margin-top: 10px;
  padding: 0 130px;
}

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  height: 600px;
}

.header-text {
  margin-top: 110px;
  max-width: 360px;
  color: var(--white);
}

.logo {
  width: 218px;
}

.hide-desktop {
  display: none;
}

.question {
  height: 25px;
}

.question-mark-wrapper {
  position: absolute;
  right: 10px;
  top: 485px;
}

@media only screen and (max-width: 600px) {
  .hide-mobile {
    display: none;
  }

  .hide-desktop {
    display: block;
  }

  .header-text {
    margin-top: 10px;
    max-width: 250px;
    font-size: var(--xl3);
    letter-spacing: 0;
    line-height: var(--xl4);
  }

  .register-button {
    padding: 0 40px;
  }

  h2 {
    margin-top: 10px;
    font-size: var(--xl2);
    line-height: var(--xl3);
  }

  .logo {
    width: 160px;
  }

  .content-wrapper {
    padding: 0 15px;
  }

  header {
    height: 520px;
    width: 100%;
    background: url("../../assets/img/hero-small-new.png");
    background-size: cover;
  }

  .header-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    height: 425px;
  }
}
</style>
