<template>
  <div class="w-full relative">
    <label>{{ label }}</label>
    <textarea v-if="textArea" v-model="inputVal" :class="{ warning: !noErrors, [currentType.toLowerCase()]: true }"
      class="default-text-input" :type="currentInputType" @blur="$emit('blur')" @focus="$emit('focus')"
      :placeholder="placeholder" />
    <input v-else v-model="inputVal" :class="{ warning: !noErrors, [currentType.toLowerCase()]: true }"
      class="default-text-input" :type="currentInputType" @blur="$emit('blur')" @focus="$emit('focus')"
      :placeholder="placeholder" />
    <search v-if="currentType.toLowerCase() === 'search'" class="searchIcon" />
    <p v-if="!noErrors" class="warning-text">
      {{ !warningLabel ? "" : warningLabel }}
      <slot v-if="!warningLabel" name="warning" />
    </p>
  </div>
</template>

<script>
import Search from "../svg/Search.vue";

export default {
  name: "DefaultTextInput",
  props: [
    "value",
    "label",
    "warningLabel",
    "type",
    "inputType",
    "textArea",
    "validation",
    "placeholder",
    "isManualValid"
  ],
  data: () => {
    return {
      isValid: true,
    };
  },
  methods: {
    async validate(value) {
      if (!this.validation) return true;

      const isValid = await this.validation(value);

      this.isValid = isValid;
      this.$emit("validate", isValid);
    },
  },
  computed: {
    noErrors() {
      return this.isManualValid === undefined ? this.isValid : this.isManualValid
    },

    currentType() {
      return this.type ? this.type : "";
    },
    currentInputType() {
      return this.inputType ? this.inputType : "text";
    },
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("inputValue", val);
        this.validate(val);
      },
    },
  },
  components: { Search },
};
</script>

<style scoped>
.default-text-input {
  height: 56px;
  border-radius: 8px;
  padding: 20px;
  background-color: #f5f8f9;
  width: 100%;
  margin-top: 16px;
}

.white {
  background-color: white;
}

.search {
  margin: 0;
  background-color: rgba(230, 232, 235);
}

.searchIcon {
  position: absolute;
  right: 15px;
  top: 17px;
}

input[type="text"]:focus {
  border: 1px solid #1d9ef9;
  border-radius: 4px;
  outline-color: #1d9ef9;
}

textarea {
  height: 200px !important;
  resize: none;
}

.warning {
  border: 1px solid red;
  outline-color: var(--red);
}

input[type="text"].warning:focus {
  border: 1px solid red;
  outline-color: var(--red);
}

.warning-text {
  color: var(--red);
  font-weight: bold;
}

label {
  color: #0b0000;
  font-size: var(--m);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
}
</style>
