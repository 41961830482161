<template>
  <div class="cta-section">
    <div class="container mx-auto content-wrapper">
      <div class="text">
        <h2>
          In <strong>2023</strong> hebben we al vele duizenden euro's uitgekeerd aan cashback.
        </h2>
        <default-button
          class="mt-10"
          v-if="!loggedIn"
          @click="$router.push({ name: 'Registration' })"
          text="Registreer nu"
          type="gradient"
        />
      </div>
      <img class="image" src="@/assets/img/cta.png" alt="raboom-cta" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultButton from "../elements/DefaultButton.vue";
export default {
  name: "CtaSection",
  components: {
    DefaultButton,
  },
  props: ["cmsData", "text"],
  computed: {
    ...mapGetters("cashback", ["loggedIn"]),
  },
};
</script>

<style scoped>
h2 {
  font-size: var(--xl6);
  line-height: var(--xl7);
  color: var(--white);
}

h2 span {
  font-weight: bold;
}

.content-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 80px 0;
}

.cta-section {
  background: #000445;
}

.text {
  max-width: 500px;
}

@media only screen and (max-width: 600px) {
  .content-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 40px 15px;
  }

  .image {
    margin-top: 40px;
    width: 90%;
  }

  h2 {
    font-size: var(--xl4);
    line-height: var(--xl5);
    color: var(--white);
  }
}
</style>
