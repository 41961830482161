<template>
  <div @click="goToShop($route, $router, shop)" class="px-3 carousel-item-big">
    <div class="px-3 content-wrapper" :style="{
      backgroundImage:
        'linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(255, 255, 255, 0)), url(' +
        shop.imageUrl +
        ')',
    }">
      <div class="circle-wrapper">
        <div class="circle">
          <img :style="{ width: '60%' }" :src="shop.store.logoSquareUrl" :alt="shop.store.name" />
        </div>
      </div>
      <h1 class="mb-3">{{ shop.store.name }}</h1>
      <p class="mb-5 max-w-xl">{{ shop.store.details }}</p>
      <span><promo-label :cashback="shop.cashback" /></span>
    </div>
  </div>
</template>

<script>
import PromoLabel from "@/components/elements/PromoLabel.vue";
import { goToShop } from "../../utils/utils";

export default {
  name: "CarouselItemBig",
  props: ["shop"],
  components: { PromoLabel },
  methods: {
    goToShop
  },
};
</script>

<style scoped>
.carousel-item-big {
  width: 620px;
  height: 450px;
  margin: 0 10px;
  cursor: pointer;
}

.content-wrapper {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 30px 30px 50px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.top-caption {
  text-align: center;
  font-size: var(--sm);
  line-height: var(--sm);
  color: var(--caption);
  padding: 10px;
  background-color: var(--caption-bg);
}

h1 {
  font-size: var(--xl4);
}

.circle {
  float: right;
  height: 100px;
  width: 100px;
  background-color: var(--white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-wrapper {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .content-wrapper {
    width: 100%;
  }
}
</style>
