<template>
  <div>
    <hero-image>
      <h1 class="title">Welkom Terug!</h1>
      <form @submit.prevent="login">
        <default-text-input
          :value="userCredentials.email"
          @inputValue="userCredentials.email = $event"
          @validate="validations.email = $event"
          label="E-mailadres"
          :validation="advanceEmailValidate"
        >
          <p slot="warning" class="warning-text">
            Dit account heeft nog geen wachtwoord, maak
            <router-link :to="{ name: 'Recovery' }">
              <a class="link">hier</a>
            </router-link>
            een wachtwoord aan.
          </p>
        </default-text-input>
        <default-text-input
          class="mt-10"
          inputType="password"
          :value="userCredentials.password"
          @inputValue="userCredentials.password = $event"
          label="Wachtwoord"
        />
        <p v-if="!validLogin" class="warning-text">
          Login gegevens zijn niet juist
        </p>
        <div class="flex items-center justify-between mt-10">
          <router-link :to="{ name: 'Recovery' }">
            <p class="text">Wachtwoord vergeten?</p>
          </router-link>
          <default-button @click="login" text="Inloggen" type="gradient" />
        </div>
      </form>
    </hero-image>
    <footer-component :sticky="true" />
  </div>
</template>

<script>
import DefaultButton from "../components/elements/DefaultButton.vue";
import DefaultTextInput from "../components/elements/DefaultTextInput.vue";
import FooterComponent from "../components/patterns/FooterComponent.vue";
import HeroImage from "../components/patterns/HeroImage.vue";
import { validateEmail } from "../utils/utils";

export default {
  name: "Login",
  components: { DefaultTextInput, HeroImage, DefaultButton, FooterComponent },
  metaInfo: {
    titleTemplate: "%s | Inloggen",
  },
  mounted() {
    const email = this.$route.query.email;

    if (email) {
      this.userCredentials.email = email.replace(" ", "+");
      this.validations.email = true;
    }
  },
  data: () => {
    return {
      userCredentials: {
        email: "",
        password: "",
        grant_type: "password",
      },
      validations: {
        email: undefined,
      },
      validLogin: true,
    };
  },
  methods: {
    validateEmail,
    async advanceEmailValidate(email) {
      if (!this.validateEmail(email)) {
        return true;
      }

      if (await this.$store.dispatch("cashback/validateEmailPassword", email)) {
        return false;
      }

      return true;
    },

    async login() {
      const { $store, $router, userCredentials } = this;
      await $store
        .dispatch("cashback/getTokens", userCredentials)
        .then(() => {
          this.validLogin = true;
        })
        .catch((e) => {
          console.error(e);
          this.validLogin = false;
        });

      if (this.validLogin) {
        await $store.dispatch("cashback/getUser");
        $router.push({ name: "Home" });
      }
    },
  },
};
</script>

<style scoped>
.warning-text {
  color: var(--red);
  margin-top: 10px;
  font-weight: bold;
}

.title {
  margin: 80px 0;
  color: var(--black);
  font-family: "GT Walsheim Pro";
  font-size: var(--xl5);
  letter-spacing: 0;
  line-height: var(--xl6);
  text-align: center;
}

q .text {
  color: var(--gray);
  font-size: var(--m);
  letter-spacing: 0;
  line-height: 24px;
}

.link {
  color: var(--caption);
}

@media only screen and (max-width: 600px) {
  .title {
    margin: 60px 0;
    font-size: var(--xl4);
    line-height: var(--xl5);
  }
}
</style>
