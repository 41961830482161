<template>
  <fragment>
    <dynamic-header :user="user" :loggedIn="loggedIn">
      <template v-slot:title>
        Jouw portemonnee
        <b>€{{ user ? user.wallet.value : "00.00" }}</b>
      </template>
    </dynamic-header>
    <div class="container mx-auto py-20">
      <cashback :transactions="transactions" :payouts="payouts" :user="user" />
    </div>
    <update-profile :user="user" />
    <cta-section />
    <footer-component />
  </fragment>
</template>

<script>
import CtaSection from "../components/patterns/CtaSection.vue";
import DynamicHeader from "../components/patterns/DynamicHeader.vue";
import FooterComponent from "../components/patterns/FooterComponent.vue";
import Cashback from "../components/patterns/Cashback.vue";
import UpdateProfile from "../components/patterns/UpdateProfile.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Account",
  components: {
    DynamicHeader,
    FooterComponent,
    CtaSection,
    Cashback,
    UpdateProfile,
  },

  metaInfo: {
    titleTemplate: "%s | Account pagina",
  },

  beforeMount() {
    if (this.loggedIn) {
      this.$store.dispatch("cashback/getTransactions");
      this.$store.dispatch("cashback/getUser");
      this.$store.dispatch("cashback/getPayouts");
    }
  },

  computed: {
    ...mapState("cashback", {
      user: (state) => state.user,
      transactions: (state) => state.transactions.reverse(),
      payouts: (state) => state.payouts.reverse(),
    }),
    ...mapGetters("cashback", ["loggedIn"]),
  },
};
</script>
