<template>
  <fragment>
    <hero-image>
      <h1 class="title">Wachtwoord aanmaken</h1>
      <default-text-input @validate="validations.email = $event" @inputValue="email = $event"
        :validation="validateEmail" warningLabel="Voer een correcte emailadres in" :value="email" label="E-mailadres" />
      <modal v-if="showModal" :close="closeModal">
        <h1>
          Email gestuurd naar <b>"{{ email }}"</b>
        </h1>
        <p>
          Als je e-mailadres in onze database bestaat, krijg je verdere
          instructies om je wachtwoord te herstellen in je inbox.
        </p>
      </modal>
      <div class="flex justify-end items-center mt-10">
        <default-button :disabled="!validations.email" text="Versturen" @click="sendPasswordReset" type="gradient" />
      </div>
    </hero-image>
    <footer-component :sticky="true" />
  </fragment>
</template>

<script>
import DefaultButton from "../components/elements/DefaultButton.vue";
import DefaultTextInput from "../components/elements/DefaultTextInput.vue";
import HeroImage from "../components/patterns/HeroImage.vue";
import FooterComponent from "../components/patterns/FooterComponent.vue";
import { validateEmail } from "../utils/utils";
import Modal from "../components/patterns/Modal.vue";

export default {
  components: {
    DefaultTextInput,
    HeroImage,
    DefaultButton,
    FooterComponent,
    Modal,
  },
  metaInfo: {
    titleTemplate: "%s | Wachtwoord herstellen",
  },
  data: () => {
    return {
      email: "",
      validations: {
        email: false,
      },
      showModal: false,
    };
  },
  methods: {
    validateEmail,
    closeModal() {
      this.showModal = false;
      this.email = "";
    },

    async sendPasswordReset() {
      await this.$store.dispatch("cashback/sendPasswordReset", this.email);
      this.showModal = true;
    },
  },
};
</script>

<style scoped>
.title {
  margin: 80px 0;
  color: var(--black);
  font-family: "GT Walsheim Pro";
  font-size: var(--xl5);
  letter-spacing: 0;
  line-height: var(--xl4);
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .title {
    margin: 60px 0;
    font-size: var(--xl4);
    line-height: var(--xl3);
  }
}
</style>
