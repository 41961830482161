<template>
  <div class="video-section flex items-center justify-center">
    <div class="video flex items-center justify-center">
      <img
        class="cursor-pointer"
        @click="toggleVideo(true)"
        src="@/assets/img/icons/play-icon.svg"
        alt="raboom-video"
      />
    </div>

    <div v-if="showVideo" class="overlay" @click="toggleVideo(false)">
      <div class="content">
        <iframe
          src="https://www.youtube.com/embed/cuseCdooRVw"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoPopupComponent",
  data: () => {
    return {
      showVideo: false,
    };
  },
  methods: {
    toggleVideo(toggle) {
      this.showVideo = toggle;
      toggle
        ? (document.body.style.overflowY = "hidden")
        : (document.body.style.overflowY = "initial");
    },
  },
};
</script>

<style scoped>
.video-section {
  padding: 20px 0 0 0;
  width: 100%;
}

.overlay {
  color: white;
  position: fixed; /* Sit on   top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  overflow: hidden;
  width: 733px;
  height: 412px;
  background-color: white;
}

iframe {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .content {
    height: 340px;
    width: 100%;
  }
}
</style>
