<template>
  <div class="explination-section">
    <div class="text-center text-wrapper">
      <h2>Hoe werkt cashback?</h2>
      <p class="text">
        Ga met één extra klik via RABOOM naar je favoriete webshops en krijgt to
        75% korting!
      </p>
    </div>
    <div class="icons-wrapper">
      <div v-for="(icon, index) in getIconInfo" :key="index" class="icon-card">
        <img
          class="icon"
          :src="require(`@/assets/img/${icon.image}`)"
          alt="raboom-icon"
        />
        <h3 class="mt-5">{{ icon.title }}</h3>
        <p class="text">{{ icon.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExplinationSection",
  props: ["cmsData"],
  computed: {
    getIconInfo() {
      return [
        {
          image: "phone.png",
          title: "Bezoek Raboom",
          description:
            "Registreer je nu en bespaar jaarlijks honderden euro's.",
        },
        {
          image: "screen.png",
          title: "Jouw favoriete webshop",
          description: "Log in en ga via RABOOM naar de webshop.",
        },
        {
          image: "money.png",
          title: "Ontvang je geld terug",
          description: "Je ontvangt automatisch cashback in je account.",
        },
      ];
    },
  },
};
</script>

<style scoped>
.explination-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
}

.text-wrapper {
  max-width: 550px;
}

h2 {
  font-size: var(--xl5);
  line-height: var(--xl6);
  margin-bottom: 24px;
}

.icons-wrapper {
  height: 350px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.text {
  font-size: var(--m);
  line-height: var(--xl);
  color: var(--gray);
  margin: 10px 0 40px;
}

.icon-card {
  max-width: 320px;
  text-align: center;
}

.icon-card img {
  margin: 0 auto;
  height: 150px;
}

h3 {
  font-size: var(--xl);
}

@media only screen and (max-width: 600px) {
  .icons-wrapper {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h2 {
    font-size: var(--xl4);
    line-height: var(--xl5);
    margin-bottom: 24px;
  }

  .text {
    margin: 10px 0 40px;
  }

  img.icon {
    height: 100px;
  }

  .explination-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 15px;
  }
}
</style>
