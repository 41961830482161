import Vue from "vue";
import App from "./App.vue";

import "@/assets/css/constants.css";
import "@/assets/css/index.css";
import router from "./router";
import store from "./store";
import VueMeta from "vue-meta";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import { Plugin } from "vue-fragment";

Vue.use(VueMeta);
Vue.use(Plugin);
Vue.use(Toast);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
