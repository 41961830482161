<template>
  <div class="count">
    {{ currentSeconds }}
  </div>
</template>

<script>
export default {
  name: "Timer",
  props: ["seconds"],
  data: () => {
    return {
      currentSeconds: null,
      timer: null
    };
  },
  mounted() {
    this.currentSeconds = this.seconds;

    this.timer = setInterval(() => {
      if (this.currentSeconds !== 0) {
        this.currentSeconds = this.currentSeconds - 1;
      }
    }, 1000);
  },
  destroyed() {
    clearInterval(this.timer)
  },
};
</script>

<style scoped>
.count {
  font-size: var(--xl3);
  color: var(--black);
  margin: 0 10px;
}
</style>
