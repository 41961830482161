<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import Default from "@/layouts/Default";
export default {
  metaInfo: {
    title: "RABOOM",
    description:
      "RABOOM is jong, slim en exclusief, registreer je bij RABOOM en ontvang cashback bij al je favoriete webshops!"
  },
  components: {
    "default-layout": Default
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "default") + "-layout";
    }
  },
  beforeCreate() {
    this.$store.dispatch("cashback/checkLocalStorage");
  }
};
</script>
