Link<template>
  <div class="question-list">
    <h1>Top 6 meest gestelde vragen</h1>
    <ul>
      <li
        v-for="question in topQuestions"
        :key="question.question"
      >
        <div class="flex justify-between items-center" @click="question.show = !question.show">
          <h2>{{ question.question }}</h2>
          <img
            :class="{ chevron: true, close: question.show }"
            src="../../assets/img/chevron-down.png"
            alt="chevron"
          />
        </div>
        <p v-show="question.show" v-html="question.answer">

        </p>
      </li>
      <h1 class="mt-10">Veelgestelde vragen</h1>
      <li
        @click="question.show = !question.show"
        v-for="(question, i) in faqQuestions"
        :key="i"
      >
        <div class="flex justify-between items-center">
          <h2>{{ question.question }}</h2>
          <img
            :class="{ chevron: true, close: question.show }"
            src="../../assets/img/chevron-down.png"
            alt="chevron"
          />
        </div>
        <p v-show="question.show">
          {{ question.answer }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import questions from "../../data/FAQData";

export default {
  name: "QuestionList",
  props: ["cmsData"],

  mounted() {
    this.topQuestions = questions
      .filter(question => question.type === "TOP")
      .map(question => ({ ...question, show: false }));

    this.faqQuestions = questions
      .filter(question => question.type === "FAQ")
      .map(question => ({ ...question, show: false }));
  },

  data: () => {
    return {
      topQuestions: [],
      faqQuestions: []
    };
  }
};
</script>

<style>
  .link {
    color: var(--caption);
  }
</style>

<style scoped>
li {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 25px 0;
  cursor: pointer;
}

li:last-of-type {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

h1 {
  color: var(--black);
  font-family: "GT Walsheim Pro";
  font-size: var(--xl4);
  font-weight: bold;
  letter-spacing: 0;
  line-height: var(--xl5);
  margin-bottom: 40px;
}

h2 {
  color: var(--black);
  font-family: "GT Walsheim Pro";
  font-size: var(--xl);
  letter-spacing: 0;
  line-height: 24px;
}

p {
  margin-top: 15px;
  max-width: 800px;
  color: var(--gray);
  font-family: "GT Walsheim Pro";
  font-size: var(--m);
  letter-spacing: 0;
  line-height: 24px;
}

.chevron {
  transition: all 0.2s linear;
  height: 10px;
  width: 16px;
}

.close {
  transform: rotate(180deg);
}

@media only screen and (max-width: 600px) {
  .question-list {
    padding: 15px;
  }

  h1 {
    font-size: var(--xl3);
    line-height: var(--xl4);
  }
}
</style>
