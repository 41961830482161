<template>
  <svg
    width="21px"
    height="19px"
    viewBox="0 0 21 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Design"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Home---Ingelogd"
        transform="translate(-1324.000000, -1047.000000)"
        :fill="fillColor"
        fill-rule="nonzero"
      >
        <g id="Group-4" transform="translate(1246.000000, 1032.000000)">
          <g id="Group-2" transform="translate(63.333333, 0.000000)">
            <path
              d="M17,29.8333333 C18.10468,29.8333333 19,30.7286533 19,31.8333333 C19,32.9380133 18.10468,33.8333333 17,33.8333333 C15.89532,33.8333333 15,32.9380133 15,31.8333333 C15,30.7286533 15.89532,29.8333333 17,29.8333333 Z M34,30.8333333 C34.55234,30.8333333 35,31.2809933 35,31.8333333 C35,32.3856733 34.55234,32.8333333 34,32.8333333 L34,32.8333333 L23,32.8333333 C22.44766,32.8333333 22,32.3856733 22,31.8333333 C22,31.2809933 22.44766,30.8333333 23,30.8333333 L23,30.8333333 Z M17,22.8333333 C18.10468,22.8333333 19,23.7286533 19,24.8333333 C19,25.9380133 18.10468,26.8333333 17,26.8333333 C15.89532,26.8333333 15,25.9380133 15,24.8333333 C15,23.7286533 15.89532,22.8333333 17,22.8333333 Z M34,23.8333333 C34.55234,23.8333333 35,24.2809933 35,24.8333333 C35,25.3856733 34.55234,25.8333333 34,25.8333333 L34,25.8333333 L23,25.8333333 C22.44766,25.8333333 22,25.3856733 22,24.8333333 C22,24.2809933 22.44766,23.8333333 23,23.8333333 L23,23.8333333 Z M17,15.8333333 C18.10468,15.8333333 19,16.7286533 19,17.8333333 C19,18.9380133 18.10468,19.8333333 17,19.8333333 C15.89532,19.8333333 15,18.9380133 15,17.8333333 C15,16.7286533 15.89532,15.8333333 17,15.8333333 Z M34,16.8333333 C34.55234,16.8333333 35,17.2809933 35,17.8333333 C35,18.3856733 34.55234,18.8333333 34,18.8333333 L34,18.8333333 L23,18.8333333 C22.44766,18.8333333 22,18.3856733 22,17.8333333 C22,17.2809933 22.44766,16.8333333 23,16.8333333 L23,16.8333333 Z"
              id="Combined-Shape"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: ["fill"],
  computed: {
    fillColor() {
      return !this.fill ? "white" : this.fill;
    }
  }
};
</script>
