<template>
  <div class="register-tab">
    <transition name="fade" mode="out-in" key="1">
      <div v-if="tabIndex === 1">
        <default-text-input
          class="mt-10"
          @inputValue="signUpCredentials.first_name = $event"
          label="Voornaam"
          :value="signUpCredentials.first_name"
          warningLabel="Voer een naam in"
          :isManualValid="validations.name"
        />
        <default-text-input
          @inputValue="signUpCredentials.birthdate = $event"
          class="mt-10"
          label="Geboortedatum"
          :value="signUpCredentials.birthdate"
          :warningLabel="advanceAgeWarning"
          :isManualValid="validations.birthdate"
        />
      </div>
      <div v-else key="2">
        <default-radio-buttons
          label="Ik zit op..."
          @radioValue="signUpCredentials.role = $event"
          :options="options"
        />

        <template v-if="signUpCredentials.role">
          <default-text-input
            class="mt-10"
            @inputValue="signUpCredentials.email = $event"
            :label="emailLabels.primary"
            :value="signUpCredentials.email"
            :warningLabel="advanceEmailWarning"
            :isManualValid="validations.email"
          >
            <p slot="warning" class="warning-text">
              Dit account heeft nog geen wachtwoord, maak
              <router-link :to="{ name: 'Recovery' }">
                <a class="link">hier</a>
              </router-link>
              een wachtwoord aan.
            </p>
          </default-text-input>
          <default-text-input
            v-if="
              !signUpCredentials.role
                ? true
                : options.includes(signUpCredentials.role) &&
                  signUpCredentials.role !== 'Tussenjaar'
            "
            warningLabel="Voer een correcte e-mailadres in"
            class="mt-10"
            @inputValue="signUpCredentials.email_secondary = $event"
            :label="emailLabels.secondary"
            :value="signUpCredentials.email_secondary"
            :isManualValid="validations.emailSecondary"
          />
          <default-text-input
            class="mt-10"
            @inputValue="signUpCredentials.password = $event"
            label="Wachtwoord"
            inputType="password"
            :validation="isNotEmpty"
            :value="signUpCredentials.password"
            :isManualValid="validations.password"
            warningLabel="Voer een wachtwoord in"
          />

          <default-check-box
            @checkboxValue="validations.parentalConsent = $event"
            v-if="!ageIsValid"
            class="mt-10"
            :value="validations.parentalConsent"
            label="Ik ben nog geen 18 jaar oud, maar heb toestemming van mijn ouders"
          />
          <default-check-box
            @checkboxValue="validations.termsAgrement = $event"
            :value="validations.termsAgrement"
            class="mt-5"
            label="Ik ga akkoord met de algemene voorwaarden en privacy beleid"
            warningLabel="Je moet akkoord gaan met onze voorwaarden."
          />
          <p>
            We brengen je standaard op de hoogte van jouw lopende bestellingen,
            opgespaard tegoed en de beste deals.
            <router-link :to="{ name: 'Terms' }"
              ><a> Algemene voorwaarden</a></router-link
            >
            en
            <router-link :to="{ name: 'Privacy' }"
              ><a>privacy beleid</a>.
            </router-link>
          </p>
        </template>
      </div>
    </transition>
    <div
      :class="{
        'justify-end': tabIndex === 1,
        'justify-between': tabIndex === 2,
      }"
      type="gradient"
      class="flex items-center mt-10"
    >
      <default-button
        :style="{ backgroundColor: '#F5F8F9' }"
        :text="tabIndex === 1 ? 'Volgende stap' : 'Vorige stap'"
        :type="tabIndex === 1 ? 'gradient' : 'gray'"
        @click="setTabIndex(tabIndex)"
      />

      <default-button
        v-if="tabIndex === 2"
        :disabled="showLoader"
        :loading="showLoader"
        type="gradient"
        text="Registreer"
        @click="signUp"
      />
    </div>
    <p class="warning-text text-right" v-if="showWarning">
      Vul het hele formulier in om
      {{ tabIndex === 1 ? "verder te gaan" : "te registreren" }}.
    </p>
  </div>
</template>

<script>
import DefaultButton from "../elements/DefaultButton.vue";
import DefaultCheckBox from "../elements/DefaultCheckBox.vue";
import DefaultRadioButtons from "../elements/DefaultRadioButtons.vue";
import DefaultTextInput from "../elements/DefaultTextInput.vue";
import {
  isNotEmpty,
  validateEmail,
  validateBirthdate,
} from "../../utils/utils";

export default {
  name: "RegisterTab",
  components: {
    DefaultButton,
    DefaultTextInput,
    DefaultRadioButtons,
    DefaultCheckBox,
  },

  computed: {
    emailLabels() {
      const role = this.signUpCredentials.role;
      const lables = {
        primary: "E-mailadres onderwijsinstelling",
        secondary: "E-mailadres privé",
      };

      if (role && !this.options.includes(role) || role === "Tussenjaar") {
        lables.primary = "E-mailadres privé";
      }

      if (role === "Middelbare school") {
        lables.primary = "E-mailadres school";
        lables.secondary = "E-mailadres privé of ouders/verzorgers";
      }

      if (role === "MBO") {
        lables.secondary = "E-mailadres privé of ouders/verzorgers";
      }

      return lables;
    },
    ageIsValid() {
      const birthdate = this.signUpCredentials.birthdate;

      if (!isNotEmpty(birthdate) || !validateBirthdate(birthdate)) {
        return false;
      }

      const birthYear = birthdate.split(/[- /]/gm)[2];
      const currentYear = new Date().getFullYear();

      return currentYear - birthYear >= 18;
    },

    firstPageIsFilled() {
      return !this.validations.birthdate ||
        !this.validations.name
        ? false
        : true;
    },

    roleIsValid() {
      return this.signUpCredentials.role !== "";
    },

    registerFormIsValid() {
      const {
        email,
        password,
        emailSecondary,
        birthdate,
        termsAgrement,
        parentalConsent,
      } = this.validations;

      if (
        (!email || !password || !birthdate,
        !termsAgrement || !this.roleIsValid)
      ) {
        return false;
      }

      if (!this.ageIsValid && !parentalConsent) {
        return false;
      }

      if (
        this.options.includes(this.signUpCredentials.role) &&
        this.signUpCredentials.role !== "Tussenjaar" &&
        !emailSecondary
      ) {
        return false;
      }

      return true;
    },
  },
  methods: {
    validateEmail,
    validateBirthdate,
    isNotEmpty,

    convertDate(input) {
      const normalizedInput = input.replace(/\//g, '-');
      const parts = normalizedInput.split('-');
      return `${parts[2]}-${parts[1]}-${parts[0]}`;
    },

    validateAge(birthdate) {
      if (!isNotEmpty(birthdate) || !validateBirthdate(birthdate)) {
        this.advanceAgeWarning =
          "Voer een correcte geboortedatum in (dd-mm-yyyy of dd/mm/yyyy)";
        return false;
      }

      const birthYear = birthdate.split(/[- /]/gm)[2];
      const currentYear = new Date().getFullYear();

      if (currentYear - birthYear <= 12) {
        this.advanceAgeWarning =
          "Je moet minimaal 12 jaar zijn om jezelf aan te kunnen melden";
        return false;
      }

      return true;
    },

    async firstFormCheck() {
      this.validations.name = this.isNotEmpty(
        this.signUpCredentials.first_name
      );

      this.validations.birthdate = this.validateAge(
        this.signUpCredentials.birthdate
      );
    },

    async secondFormCheck() {
      this.validations.email = await this.validateEmailNew(
        this.signUpCredentials.email
      );
      this.validations.emailSecondary = this.validateEmail(
        this.signUpCredentials.email_secondary
      );
      this.validations.password = this.isNotEmpty(
        this.signUpCredentials.password
      );
    },

    async validateEmailNew(email) {
      if (!this.validateEmail(email)) {
        this.advanceEmailWarning = "Voer een correcte email adres in";
        return false;
      }

      // if (await this.$store.dispatch("cashback/validateEmailPassword", email)) {
      //   this.advanceEmailWarning = null;
      //   return false;
      // }

      // if (!(await this.$store.dispatch("cashback/validateEmail", email))) {
      //   this.advanceEmailWarning = "E-mailadres is al in gebruik";
      //   return false;
      // }

      return true;
    },

    async setTabIndex(currentIndex) {
      this.showWarning = false;
      if (currentIndex === 1) {
        await this.firstFormCheck();

        return this.firstPageIsFilled
          ? (this.tabIndex = 2)
          : (this.showWarning = true);
      } else {
        return (this.tabIndex = 1);
      }
    },
    async signUp() {
      this.showLoader = true;
      this.showWarning = false;
      
      await this.secondFormCheck();

      if (!this.registerFormIsValid) {
        this.showWarning = true;
        this.showLoader = false;
        return;
      }

      let { email, email_secondary, first_name, password, role, birthdate } = this.signUpCredentials
      birthdate = this.convertDate(birthdate)
      const body = { email, email_secondary, first_name, password, role, birthdate }

      await this.$store
        .dispatch("cashback/signUp", body)
        .then(() => {
          this.validSignup = true;
        })
        .catch(() => {
          this.$toast.error("Er is al een account geregistreerd met dit e-mailadres.");
          this.validSignup = false;
        })
        .finally(() => {
          this.showLoader = false;
        })

      if (this.validSignup) {
        await this.$store.dispatch("cashback/getUser");
        this.$router.push({ name: "Home" });
        this.showWarning = false;
      }
    },
  },
  data: () => {
    return {
      tabIndex: 1,
      options: ["Middelbare school", "Tussenjaar", "MBO", "HBO", "WO"],
      signUpCredentials: {
        email: "",
        password: "",
        email_secondary: "",
        birthdate: "",
        role: "",
        first_name: "",
      },
      validations: {
        email: undefined,
        password: undefined,
        emailSecondary: undefined,
        birthdate: undefined,
        parentalConsent: undefined,
        termsAgrement: undefined,
        name: undefined,
      },
      showWarning: false,
      showLoader: false,
      advanceAgeWarning:
        "Voer een correcte geboortedatum in (dd-mm-yyyy of dd/mm/yyyy)",
      advanceEmailWarning: "Voer een correcte e-mailadres in",
    };
  },
};
</script>

<style scoped>
p {
  color: var(--black);
  font-family: "GT Walsheim Pro";
  font-size: var(--m);
  letter-spacing: 0;
  line-height: 24px;
  margin: 40px 0;
}

a {
  color: rgb(29, 158, 249);
}

.warning-text {
  color: var(--red);
  margin-top: 10px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
 {
  opacity: 0;
}

.register-tab {
  margin: 80px 0;
}

@media only screen and (max-width: 600px) {
  .register-tab {
    margin: 60px 0;
  }
}
</style>
