<template>
  <div class="mx-auto full">
    <div :class="{ ['place-holder']: sticky, hide: !sticky }" />
    <div
      class="flex footer border-t-2 border-gray-100 no-sticky"
      :class="{ sticky }"
    >
      <div class="flex items-center collumn mr-10">
        <router-link :to="{ name: 'Home' }" class="mr-5">
          <img src="@/assets/img/icons/logo-color.svg" alt="Raboom-logo" />
        </router-link>

        <span class="text-gray-500"
          >©{{ new Date().getFullYear() }} Knaek B.V.</span
        >
      </div>
      <ul class="flex collumn" style="color: #1D9EF9;">
        <router-link :to="{ name: 'Privacy' }">
          <li class="link">Privacy beleid</li>
        </router-link>
        <router-link :to="{ name: 'Terms' }">
          <li class="link">Algemene voorwaarden</li>
        </router-link>
        <router-link :to="{ name: 'Support' }">
          <li class="link">Support</li>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  props: ["sticky"],
};
</script>

<style scoped>
.footer {
  display: flex;
  align-items: center;
  padding: 40px 40px;
}

.link {
  margin-right: 40px;
}

.full {
  width: 100%;
  padding: 0 20px;
}

.hide {
  display: none;
}

.place-holder {
  height: 90px;
  width: 100%;
  margin-top: 20px;
}

.sticky {
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: white;
}

.trust-pilot {
  width: 100px;
  margin-left: 30px;
}

@media only screen and (max-width: 600px) {
  .no-sticky {
    position: initial;
  }

  .footer {
    flex-direction: column;
    text-align: center;
  }

  .collumn {
    flex-direction: column;
  }

  .trust-pilot {
    margin: 30px 0 0 0;
  }

  .link {
    margin-left: 0;
    margin-top: 30px;
  }
}
</style>
