<template>
  <fragment>
    <dynamic-header :title="headerTitle" :user="user" :loggedIn="loggedIn">
      <video-popup-component />
    </dynamic-header>

    <div class="container mx-auto py-20">
      <questions-list />
    </div>
    <message-section />
    <div class="container mx-auto">
      <explination-section />
    </div>
    <cta-section :text="{ cashback: '2.083.860', members: '123.178' }" />
    <footer-component />
  </fragment>
</template>

<script>
import CtaSection from "../components/patterns/CtaSection.vue";
import DynamicHeader from "../components/patterns/DynamicHeader.vue";
import ExplinationSection from "../components/patterns/ExplinationSection.vue";
import FooterComponent from "../components/patterns/FooterComponent.vue";
import MessageSection from "../components/patterns/MessageSection.vue";
import QuestionsList from "../components/patterns/QuestionsList.vue";
import VideoPopupComponent from "../components/patterns/VideoPopupComponent.vue";
import { mapState, mapGetters } from "vuex";
import { getFirstPartOfEmail } from "../utils/utils";

export default {
  name: "Support",
  components: {
    CtaSection,
    FooterComponent,
    ExplinationSection,
    MessageSection,
    QuestionsList,
    DynamicHeader,
    VideoPopupComponent,
  },
  methods: { getFirstPartOfEmail },
  metaInfo: {
    titleTemplate: "%s | Support pagina",
  },
  computed: {
    ...mapState("cashback", {
      user: (state) => state.user,
    }),
    ...mapGetters("cashback", ["loggedIn"]),

    headerTitle() {
      return this.loggedIn && this.user
        ? `Heb je een vraag ${this.user.first_name
          ? this.user.first_name
          : this.user.email.split("@")[0]
        }?`
        : "Heb je een vraag?";
    },
  },

  beforeMount() {
    if (this.loggedIn) {
      this.$store.dispatch("cashback/getUser");
    }
  },
};
</script>
