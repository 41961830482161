<template>
  <fragment>
    <dynamic-header title="Algemene voorwaarden" :user="user" :loggedIn="loggedIn" />
    <div class="container mx-auto py-20">
      <div class="terms" v-html="content" />
    </div>
    <footer-component />
  </fragment>
</template>

<script>
import DynamicHeader from "../components/patterns/DynamicHeader.vue";
import FooterComponent from "../components/patterns/FooterComponent.vue";
import { mapState, mapGetters } from "vuex";
import { TERMS } from "../data/terms";

export default {
  name: "Terms",
  components: { FooterComponent, DynamicHeader },
  metaInfo: {
    titleTemplate: "%s | Algemene voorwaarden"
  },
  computed: {
    ...mapState("cashback", {
      user: state => state.user
    }),
    ...mapGetters("cashback", ["loggedIn"]),

    content() {
      return TERMS.html;
    }
  },

  beforeMount() {
    if (this.loggedIn) {
      this.$store.dispatch("cashback/getUser");
    }
  }
};
</script>

<style>
.terms h1 {
  font-size: var(--xl5);
  line-height: var(--xl6);
  margin: 0 0 20px 0;
}

.terms p {
  margin: 20px 0;
}

.terms a {
  color: var(--caption);
}

@media only screen and (max-width: 600px) {
  .terms h1 {
    font-size: var(--xl3);
    line-height: var(--xl4);
    margin: 0 0 20px 0;
  }

  .terms {
    padding: 15px;
  }
}
</style>
