<template>
  <div class="video-section inline-flex items-center justify-around">
    <div class="text">
      <h2>
        <span>Cashback</span>
        is even wennen, maar geeft je wel de hoogste kortingen.
      </h2>
      <p>
        Cashback korting betekent dat je een deel van het aankoopbedrag terug
        krijgt van je online aankoop. Op deze manier kunnen we hoge kortingen
        voor je regelen die nergens anders te vinden zijn.
      </p>
    </div>
    <div class="video flex items-center justify-center">
      <img
        v-if="!showVideo"
        class="cursor-pointer"
        @click="showVideo = true"
        src="@/assets/img/icons/play-icon.svg"
        alt="raboom-video"
      />
      <iframe
        v-if="showVideo"
        src="https://www.youtube.com/embed/cuseCdooRVw?autoplay=1"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoSection",
  props: ["cmsData", "text"],
  computed: {
    splitTitle() {
      const { title } = this.text;

      if (!title) {
        return { firstWord: "", rest: "" };
      }

      return {
        firstWord: title.split(" ")[0],
        rest: title.substring(title.indexOf(" ") + 1)
      };
    }
  },
  data: () => {
    return {
      showVideo: false
    };
  }
};
</script>

<style scoped>
h2 {
  font-size: var(--xl5);
  line-height: var(--xl6);
  margin-bottom: 24px;
}

iframe {
  width: 100%;
  height: 100%;
}

p {
  font-size: var(--m);
  line-height: var(--xl);
  color: var(--gray);
}

span {
  font-weight: bold;
}

.cursor-pointer {
  position: absolute;
}

.video-section {
  padding: 60px 0;
  width: 100%;
}

.text {
  max-width: 400px;
}

.video {
  background: url("../../assets/img/video.png") no-repeat center center;
  height: 412px;
  width: 733px;
  background-size: contain;
}

@media only screen and (max-width: 600px) {
  h2 {
    font-size: var(--xl4);
    line-height: var(--xl5);
  }

  .video-section {
    flex-direction: column-reverse;
    padding: 0 15px;
    text-align: center;
  }

  .video {
    background: url("../../assets/img/video-small.png") no-repeat center center;
    height: 340px;
    width: 100%;
    background-size: contain;
  }

  .text {
    margin-top: 20px;
  }
}
</style>
